import React from 'react';
import { SubmitButton } from '../../components/Button';
import { Input } from '../../components/Form';

import './styles.css';

const RecoverPassword = props => {
  return (
    <div className="recover-password-screen">
      <main>

        <h1>Recuperação de senha</h1>

        <form>

          <p>Informe seu e-mail para enviarmos as instruções de recuperação da sua senha</p>

          <Input placeholder="Email" />

          <SubmitButton>Enviar</SubmitButton>

        </form>

      </main>
      
    </div>
  )
}

export default RecoverPassword;
