import react, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getTournaments } from '../../../providers/betsProvider';
import jokeImage from '../../../assets/landpage/joker.png';
import trophyImage from '../../../assets/landpage/throphy.png';
import './style.css';

const Tournaments = () => {

  const [tournaments, setTournaments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const result = await getTournaments();
      setLoading(false);
      if(result.error) {
        setError("Ocorreu um erro e não foi possível exibir os torneios");
        return;
      }
      setTournaments(result);
    }
    getData();
  }, [])

  const getTournamentClassName = (type) => {
    switch (type) {
      case "High":
        return "high-level";
  
      case "Mid":
        return "mid-level";
    
      default:
        return "low-level";
    }
  }

  return (
    <section className="landing-section" id="tournaments">

      <div className="tour-row-container">
        <img className="tour-joke-image" src={jokeImage} alt="joke-image" />
        <div className="tour-infor1-container">
          <p className="tour-infor1-title">O Que é o Jogo Curinga</p>
          <p className="tour-infor-text">É um jogo escolhido por você... onde a pontuação obtida é dobrada.</p>
          <p className="tour-infor-text">Isso vai aumentar as suas chances de fazer uma pontuação maior mesmo errando em alguns jogos.</p>
        </div>
      </div>

      <div className="tour-center-container">
        <p className="tour-infor2-title">Exemplo de Premiação</p>
        <div className="tour-line-title"></div>
        <p className="tour-infor-text">Os jogadores dos torneios ficam o total arrecado no bolão diminuído o rake da plataforma.</p>
        <p className="tour-infor-text">Uma rodada com 5.000 inscritos e buy in de R$10 reais tem uma premiação total de R$40.000 ( Total arrecadado na rodada - rake/comissão da plataforma).</p>
        <p className="tour-infor-text">Do total de inscritos 10% levam a premiação na seguinte ordem. em uma rodada com 5.000 inscritos 500 serão premiados.</p>
      </div>

      <div className="tour-row-container">
        <img className="tour-joke-image" src={trophyImage} alt="joke-image" />
        <div className="tour-infor1-container">
          <p className="tour-infor1-title">Simulação Torneio R$10</p>
          <p className="tour-infor-row-main">1º Colocado - R$ 10.870,00</p>
          <p className="tour-infor-row-text">2º Colocado - R$ 5.436,00</p>
          <p className="tour-infor-row-text">3º Colocado - R$ 3.020,00</p>
          <p className="tour-infor-row-text">4º Colocado - R$ 2.416,00</p>
          <p className="tour-infor-row-text">5º Colocado - R$ 2.114,00</p>
          <p className="tour-infor-row-text">6º Colocado - R$1.812,00</p>
          <p className="tour-infor-row-text">7º Colocado - R$1.510,00</p>
          <p className="tour-infor-row-text">8º Colocado - R$1.208,00</p>
          <p className="tour-infor-row-text">9º Colocado - R$1.057,00</p>
          <p className="tour-infor-row-text">10º Colocado - R$755,00</p>
          <p className="tour-infor-row-text">11º ao 500º Colocado - R$20,00</p>
        </div>
      </div>
        
      <p className="tour-title-text">Torneios</p>

      <ul className="tournaments-container">

        {tournaments.map(item => (
          <li className="tournament-container-card" key={`tournament-${item.id}`}>
            <h3>{item.level.name}</h3>
            <div className="tournament-card">
              <p className="price">Z$ {item.level.registration_fee}</p>
              <ul className="tournament-info">
                <li>Premiação: Z$ {item.prize}</li>
                <li>Participantes: {item.number_participants}</li>
                <li>Aposte até {new Date(item.tournament.deadline).toLocaleDateString('pt-br', {hour: 'numeric', minute:'numeric'})}</li>
              </ul>
              <Link to="/login" className="btn-link btn-outline">Participar</Link>
            </div>
          </li>
        ))}

      </ul>

    </section>
  );
}

export default Tournaments;