import React from 'react';
import { ToggleContainer } from '.';

/* 
  <label>
    <input /> //hidden
    <span>Text goes here as children<span> //pseudoelement after with toogle style
  </label>
*/

const ToggleInput = ({ children, className, checked, ...props }) => (
  <ToggleContainer checked={checked} className={className}>
    <input type="checkbox" checked={checked} {...props} />
    <span>{children}</span>
  </ToggleContainer>
)

export default ToggleInput;
