import React from 'react';
import { CheckboxContainer } from '.';

/* 
  <label>
    <input /> //hidden
    <span>Text goes here as children<span> //pseudoelement before with checkbox style
  </label>
*/

const Checkbox = ({ children, className, checked, ...props }) => (
  <CheckboxContainer checked={checked} className={className}>
    <input type="checkbox" checked={checked} {...props} />
    <span>{children}</span>
  </CheckboxContainer>
)

export default Checkbox;
