import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SubmitButton } from '../../components/Button';
import { Alert, UnderlinedInput, UnderlinedTextarea, AlertSuccess } from '../../components/Form';
import { sendContactMessage } from '../../providers/userServicesProvider';

import './styles.css';

const initialPayload = {
  title: "",
  message: "",
}

const Contact = props => {

  const user = useSelector(state => state.auth.user);
  const [payload, setPayload] = useState(initialPayload);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const onChangePayload = e => {
    setPayload({...payload, [e.target.name]: e.target.value});
  }

  const isValid = () => {
    if(Object.values(payload).some(value => !value)) {
      setError("Preencha todos os campos para enviar sua mensagem");
      return false;
    }

    return true;
  }

  const send = async () => {
    if(!isValid()) {
      return;
    }
    setLoading(true);
    setError("")
    setSuccess("");

    const result = await sendContactMessage({...payload, user: user.id}, user.token);
    setLoading(false);
    if(result.error) {
      setError("Ocorreu um erro com seu cadastro. Tente novamente!");
    } else {
      setSuccess("Mensagem enviada com sucesso!");
      setPayload(initialPayload);
    }
    setTimeout(() => {
      setError("");
      setSuccess("");
    }, 3500);
  }

  return (
      <main className="contact-screen">

        <h1>Contato</h1>

        <form>
          <div className="general-info">
            <label>
              Título
              <UnderlinedInput name="title" value={payload.title} onChange={onChangePayload} />
            </label>
            <label>
              Mensagem
              <UnderlinedTextarea name="message" value={payload.message} onChange={onChangePayload} />
            </label>
          </div>

        </form>

        <Alert className="feedback-msg" show={error}>{error}</Alert>
        <AlertSuccess className="feedback-msg" show={success}>{success}</AlertSuccess>

        <SubmitButton disabled={loading} onClick={send}>
          {loading ? "Enviando..." : "Enviar"}
        </SubmitButton>

      </main>
      
  )
}

export default Contact;
