import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: auto;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  
  border-top: 2px solid white;
  border-right: 2px solid white;
  border-bottom: none;
  border-left: none;
  background: transparent;
  border-radius: 50%;
  ${ ({ size }) => size === 'large' ? `
    width: 50px;
    height: 50px;
  ` : `
    width: 25px;
    height: 25px;
  `}
`;

export default Spinner;