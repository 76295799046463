import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import UserAvatar from '../../components/UserAvatar';
import { BackgroundWarn, MainContainer, SelectContainer } from './stylesComponents';
import * as Tabs from '../../components/Tabs/Tabs';
import { getUserTournaments, getMyTournament } from '../../providers/betsProvider';
import Spinner from '../../components/LoadingSpinner';
import rankingIcon from '../../assets/icons/ranking.png';

const MyTournaments = () => {

  const user = useSelector(state => state.auth.user);
  const [activeTab, setActiveTab] = useState(0);

  const [loading, setLoading] = useState(false);

  const [loadedTournaments, setLoadedTournaments] = useState(false);
  const [tournaments, setTournaments] = useState([]);

  const [selected, setSelected] = useState('');
  const [tournamentInfor, setTournamentInfor] = useState(null);
  const [currentTournament, setCurrentTournament] = useState(null);

  useEffect(() => {
    loadTournaments();
  }, [])

  // useEffect(() => {
  //   console.log(tournaments)
  // }, [tournaments])

  useEffect(() => {
    if (selected) {
      loadInfor();
    }
  }, [selected])

  const loadTournaments = async () => {
    const response = await getUserTournaments(user.token, user.id);
    if (response) {
      // setTournaments(response);
      setTournaments([]);
      setLoadedTournaments(true);
    }
  }

  const loadInfor = async () => {
    setLoading(true);
    const response = await getMyTournament(user.token, user.id, selected);
    setLoading(false);
    if (response) {
      setTournamentInfor(response);
      setCurrentTournament(response[0]);
      onChangeTab(0);
    }
  }

  const onChangeTab = (index) => {
    if (index === activeTab) return;
    setActiveTab(index);
  }

  console.log(loadedTournaments)

  return (<>
    <aside className="ranking-screen">
      <UserAvatar image="https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg" />

      <p className="user-name">Teste</p>

      {currentTournament ? <div className="my-ranking">
        <>
          <h3>Minha Colocação</h3>
          <p>{currentTournament.position}º</p>

          <h3>Premiação</h3>
          <p>Z$ {currentTournament.value}</p>

          <h3>Minha Pontuação</h3>
          <p>{currentTournament.points} - PTS</p>
        </>
      </div> : null}

    </aside>
    <MainContainer>
      {loadedTournaments && tournaments.length > 0 ?
        <>
          <label className="labelSelect" for="select">Escolha o Torneio</label> <br />
          <SelectContainer onChange={({ target }) => setSelected(Number(target.value))}>
            <option hidden defaultChecked>TORNEIO</option>
            {tournaments.map((item, index) => (
              <option value={item.id} key={index}>{item.name}</option>
            ))}
          </SelectContainer>

          {tournamentInfor ? <Tabs.default>
            <Tabs.TabList>
              {tournamentInfor.map((item, index) => (
                <Tabs.Tab
                  active={activeTab == index}
                  onClick={() => onChangeTab(index)}
                  key={`tab-${item.id}`}
                >
                  {item.subscription.sub.level.name}
                </Tabs.Tab>
              ))}
            </Tabs.TabList>
          </Tabs.default> : null}

          {loading ? <Spinner size="large" /> :
            currentTournament ?
              <>
                <div className="inforRow">
                  <p>Acerto de empates: <span>{currentTournament.draw}</span></p>
                </div>
                <div className="inforRow">
                  <p>Placar exato: <span>{currentTournament.exact_score}</span></p>
                </div>
                <div className="inforRow">
                  <p>Acerto de Vencedor da partida: <span>{currentTournament.match_winner}</span></p>
                </div>
                <div className="inforRow">
                  <p>Premio total: <span>Z$ {currentTournament.subscription.sub.prize}</span></p>
                </div>
                <div className="inforRow">
                  <p>Numero de participantes: <span>{currentTournament.subscription.sub.number_participants}</span></p>
                </div>
              </>
              : null}
        </> : null}

      {!loadedTournaments ? <Spinner size="large" /> : null}

      {loadedTournaments && tournaments.length < 1 ?
        <BackgroundWarn>
          <img src={rankingIcon} alt='tournament'/>
          <p>Você não participou de nenhum torneio anterior.</p>
        </BackgroundWarn> : null}
    </MainContainer>
  </>)
}

export default MyTournaments;