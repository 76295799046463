import styled from  'styled-components';

import facebookIcon from "../../assets/icons/facebook.png";

const Button = styled.button`
  cursor: pointer;
`

export const SubmitButton = styled(Button)`
  min-width: 175px;
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  margin: 0 auto;
  display: block;
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
  background-color: ${({disabled}) => disabled ? 'var(--bg-quaternary)' : 'var(--action-primary)'};
`;

export const OutlineButton = styled(SubmitButton)`
  background-color: transparent;
  border: ${({borderColor=`var(--bg-secondary)`}) => `1px solid ${borderColor}`};

  &.pagination-btn {
    text-transform: none;
    padding: .5rem;
  }
`

export const FacebookButton = styled(SubmitButton)`
  background-color: #019AD9;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  &::before {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    background-image: ${`url(${facebookIcon})`};
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 0.5rem;
  }
  @media (max-width: 350px) {
    font-size: 0.875rem;
  }
`;

export const ImageButton = styled(Button)`
  font-size: 0;
  padding: 0;
  width: ${({ width = "32px" }) => `${width}`};
  height: ${({ height = "32px" }) => `${height}`};
  background-image: ${props => `url(${props.icon})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;


export const WhatssapContainer = styled.div`
  margin-top: 42px;
  text-align: center;

  img {
    width: 84px;
    cursor: pointer;
  }
`;