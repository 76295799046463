import React from 'react';

import ScreenShort from '../../../assets/landpage/platform-screen.png';
import BagMoney from '../../../assets/landpage/bag-of-money.png';
import './style.css';

const BetSection = () => {

  return (
    <section className="section-bets-infor">
      <p className="bets-title">Como São os Palpites?</p>
      <div className="bets-line"></div>
      <p className="bets-subtitle">É exatamente igual a um bolão normal, onde você escolhe o resultado das partidas, por exemplo:</p>
      <img className="bets-image" src={ScreenShort} alt="screen short" />
      <p className="bets-subtitle">Ao final da rodada os pontos são computados com base nos resultados dos jogos da rodada.</p>
      <p className="bets-subtitle">10% do total de inscritos serão premiados e a grande premiação fica com 10 primeiros colocados.</p>
      <p className="bets-subtitle">O primeiro leva a grande bolada.</p>

      <p className="bets-title">Como É Calculada a Pontuação</p>
      <div className="bets-line"></div>
      <ul className="bets-list-container">
        <li>
          <img className="bets-money" src={BagMoney} alt="dinheiro" />
          <span className="bets-list-text">Acertou Tudo (Placar e Vencedor da Partida) - 30 Pontos</span>
        </li>
        <li>
          <img className="bets-money" src={BagMoney} alt="dinheiro" />
          <span className="bets-list-text">Acertou Apenas o Resultado Mas Errou o Placar (Vitória) - 10 Pontos</span>
        </li>
        <li>
          <img className="bets-money" src={BagMoney} alt="dinheiro" />
          <span className="bets-list-text">Errou o Resultado Mas Acertou Um Dos Placares - 2 pontos</span>
        </li>
        <li>
          <img className="bets-money" src={BagMoney} alt="dinheiro" />
          <span className="bets-list-text">Jogo Curinga - Pontuação Obtida É Dobrada</span>
        </li>
      </ul>
    </section>
  )
}

export default BetSection;