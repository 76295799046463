import styled from "styled-components";

export const ConvertModalContent = styled.div`
  border-radius: 7px;
  padding: 1.5rem;
  width: 320px;
  background-color: var(--bg-secondary);

  & > h2.title {
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 0;
    margin-bottom: 1rem;
  }

  p {
    margin: 1rem 0;
    font-size: 1rem;
  }

  .feedback-msg {
    margin: 1rem 0;
  }

  .submit-btn {
    max-width: 100%;
  }

  .options {
    margin: 0.5rem 0;
  }
  .submit-btn {
    margin-top: 1.5rem;
    min-width: unset;
    padding: 0.5rem 2rem;
  }
`;