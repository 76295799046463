import React, { useEffect, useState } from "react";

import { UserBetsModalContent, BetItem } from './userBetsModalStyles';
import Modal from "../Modal/Modal";
import { Alert } from "../Form/index";
import { getGuessesByTournament } from '../../providers/betsProvider';
import { useSelector } from "react-redux";
import Spinner from "../LoadingSpinner";
import * as Tabs from '../../components/Tabs/Tabs';


const UserBetsModal = (props) => {

  const user = useSelector(state => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [tabRounds, setTabRounds] = useState([]);
  const [activeTab, setActiveTab] = useState(0);


  useEffect(() => {
    setError('');
    setTabRounds([]);
    setActiveTab(0);
  }, [props.isOpen]);

  useEffect(() => {

    if (!props.selected || !props.tournament) return;

    const getData = async () => {
      setLoading(true);
      const result = await getGuessesByTournament(user.token, props.selected.subscription.id, props.tournament);

      //filter result by level
      const list = result.filter(item => {
        return item.subscription.sub.level.id === props.level;
      })

      //group guesses by round
      // rounds = [
      //   {
      //     round: {},
      //     guesses: [],
      //   }
      // ]
      const roundList = list.reduce((rounds, guess) => {
        const currentRound = rounds.find(item => item.round.id === guess.match.game_round.id)
        if (currentRound) {

          return rounds.map(item => {
            if (item.round.id !== currentRound.round.id) {
              return item;
            }
            return {
              round: item.round,
              guesses: [...item.guesses, guess]
            }
          })

        } else {
          return [
            ...rounds,
            {
              round: guess.match.game_round,
              guesses: [guess],
            }
          ]
        }
      }, [])

      console.log(roundList);
      setTabRounds(roundList)

      setLoading(false);
    }

    getData();


  }, [props.selected, props.tournament, props.level])


  const renderGuesses = () => {
    if (tabRounds.length === 0) return null;

    return tabRounds[activeTab].guesses.map(guess => (
      <BetItem key={`guess-${guess.id}`}>
        <div className="container-match">
          <div className="team-info">
            <img src={guess.match.home_team.crest} alt={guess.match.home_team.name} />
            {guess.match.home_team.name}
          </div>
          <div className="scores">
            <input className="score-input" type="number"
              value={guess.home_team_score}
              readOnly
            />
            x
            <input className="score-input" type="number"
              value={guess.away_team_score}
              readOnly
            />
          </div>
          <div className="team-info">
            <img src={guess.match.away_team.crest} alt={guess.match.away_team.name} />
            {guess.match.away_team.name}
          </div>
        </div>
        <div className="points">
          <span>{guess.earned_score} Pts</span>
        </div>
      </BetItem>
    ))
  }


  return (
    <Modal toggle={props.toggle} isOpen={props.isOpen}>
      <UserBetsModalContent>
        <h4>Palpites de {props.selected?.subscription?.user?.name}</h4>
        {loading ? <Spinner size="large" /> : null}
        <Tabs.default>
          <Tabs.TabList>
            {tabRounds.map((item, index) => (
              <Tabs.Tab
                active={activeTab == index}
                onClick={() => setActiveTab(index)}
                key={`round-tab-${item.round.id}`}
              >
                {item.round.name}
              </Tabs.Tab>
            ))}
          </Tabs.TabList>
        </Tabs.default>
        <ul className="bet-list">
          {renderGuesses()}
        </ul>
        <Alert className="feedback-msg" show={error}>{error}</Alert>
      </UserBetsModalContent>
    </Modal>
  );
};

export default UserBetsModal;
