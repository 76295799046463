import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { ImportBetsContent } from "./importBetsStyles.js";
import { Alert, AlertSuccess, RadioLabel } from "../Form/index";
import { SubmitButton } from "../Button";
import { useSelector } from "react-redux";
import { importBets } from "../../providers/userServicesProvider";


const ImportBetsModal = (props) => {

  const user = useSelector(state => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setOptions(
      props.subscriptions.filter(item => 
        item.id !== props.currentSubscription.id &&
        item.sub.tournament.id === props.currentSubscription.sub.tournament.id
      )
    );
  }, [props.currentSubscription, props.subscriptions]);

  const onChangeOption = (e) => {
    setSelected(e.target.name);
  }

  const send = async () => {
    if(loading) return;
    setError("");
    setSuccess("");

    setLoading(true);
    //call api
    const result = await importBets(user.id, selected, props.currentSubscription.id, user.token);
    setLoading(false);
    //treat error
    if(result.error) {
      setError("Ocorreu um erro e não foi possível importar seus palpites");
      setTimeout(() => setError(""), 5000);
      return;
    }
    setSuccess("Palpites importados com sucesso");
    //update balance via prop function
    if(props.onSuccess) {
      props.onSuccess();
    }
    setTimeout(() => setSuccess(""), 800);
  }

  return (
    <Modal toggle={props.toggle} isOpen={props.isOpen}>
      <ImportBetsContent>
        <h2 className="title">Importar Palpites para {props.currentSubscription.sub?.level.name}</h2>

        <p>Escolha outro torneio do qual quer importar palpites</p>

        {options.map(item => (
          <RadioLabel className="options" key={`option-${item.id}`}>
            <input type="radio" name={item.id} checked={item.id == selected} onChange={onChangeOption}/>
            <span>{item.sub.level.name} - {item.sub.tournament.name}</span>
          </RadioLabel>
        ))}
        
        <Alert className="feedback-msg" show={error}>{error}</Alert>
        <AlertSuccess className="feedback-msg" show={success}>{success}</AlertSuccess>
        <SubmitButton className="submit-btn" onClick={send}>
          {loading ? 'Carregando...' : 'Importar'}
        </SubmitButton>
      </ImportBetsContent>
    </Modal>
  );
};

export default ImportBetsModal;
