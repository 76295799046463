import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { CheckoutContent } from "./checkoutStyles";
import { Alert, AlertSuccess, UnderlinedInput } from "../Form/index";
import { SubmitButton } from "../Button";
import { useSelector } from "react-redux";
import { buyCredit } from "../../providers/userServicesProvider";

const initialPayload = {
  user: "",
  holder_name: "",
  exp_month: new Date().getMonth() + 1,
  exp_year: new Date().getFullYear(),
  number: "",
  ccv: "",
  transaction_type: 'Créditos',
  total_amount: "0.00",
  amount_credit: "0", //creditos comprados
  description: "",
  taxpayer: "",
}

const CheckoutModal = (props) => {

  const [payload, setPayload] = useState(initialPayload);
  const user = useSelector(state => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if(user.id) {
      setPayload({...payload, user: user.id});
    }
  }, [user.id]);

  const onChangePayload = e => {
    if(e.target.name === "exp") {
      const [exp_month, exp_year] = e.target.value.split("/");
      setPayload({...payload, exp_month, exp_year: exp_year === undefined ? "" : exp_year});
      return;
    }
    const newPayload = {
      ...payload,
      [e.target.name]: e.target.value,
      total_amount: e.target.name === "amount_credit" ? 
        calcPaymentValue(e.target.value) : payload.total_amount,
      description: e.target.name === "amount_credit" ? 
      `Compra de ${e.target.value} créditos no app Zé do Bet` : payload.description,
    }
    setPayload(newPayload);
  }

  const isValid = () => {
    if(Object.values(payload).some(value => !value)) {
      setError("Preencha todos os campos para adquirir créditos");
      return false;
    }
    return true;
  }

  const send = async () => {
    if(loading) return;
    setError("");
    setSuccess("");

    //validation...
    if(!isValid()) return;

    setLoading(true);
    //call api
    const result = await buyCredit(payload, user.token);
    setLoading(false);
    //treat error
    if(result.error) {
      setError("Ocorreu um erro e não foi possível realizar o pagamento");
      return;
    }
    setSuccess("Pagamento realizado com sucesso");
    //update balance via prop function
    if(props.onSuccess) {
      props.onSuccess();
    }
  }

  return (
    <Modal toggle={props.toggle} isOpen={props.isOpen}>
      <CheckoutContent>
        <h2>Adicionar Créditos</h2>
        <form>
          <label>
            Quantidade de créditos
            <UnderlinedInput
              name="amount_credit"
              type="number"
              value={payload.amount_credit}
              onChange={onChangePayload}
            />
          </label>
          <div className="card-info">
          <label id="card-number">
            Número do cartão
            <UnderlinedInput
              name="number"
              value={payload.number}
              onChange={onChangePayload}
            />
          </label>
          <label id="validity">
            Validade (mm/aaaa)
            <UnderlinedInput
              name="exp"
              value={`${payload.exp_month}/${payload.exp_year}`}
              onChange={onChangePayload}
              placeholder="mm/aaaa"
            />
          </label>
          <label id="security-code">
            CVV
            <UnderlinedInput
              name="ccv"
              value={payload.ccv}
              onChange={onChangePayload}
            />
          </label>
          <label id="holder-name">
            Nome do Titular
            <UnderlinedInput
              name="holder_name"
              value={payload.holder_name}
              onChange={onChangePayload}
            />
          </label>
          <label id="taxpayer">
            CPF
            <UnderlinedInput
              name="taxpayer"
              value={payload.taxpayer}
              onChange={onChangePayload}
            />
          </label>
          </div>
        </form>
        <p className="price">Total a pagar: <span>R$ {payload.total_amount.replace(".", ",")}</span></p>
        <Alert className="feedback-msg" show={error}>{error}</Alert>
        <AlertSuccess className="feedback-msg" show={success}>{success}</AlertSuccess>
        <SubmitButton className="submit-btn" onClick={send}>
          {loading ? 'Carregando...' : 'Realizar pagamento'}
        </SubmitButton>
      </CheckoutContent>
    </Modal>
  );
};

const calcPaymentValue = (credits) => {
  if(!credits) {
    return "0,00";
  }
  return parseFloat(credits).toFixed(2).toString();
}

export default CheckoutModal;
