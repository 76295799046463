import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FacebookButton, SubmitButton } from '../../components/Button';
import { Alert, Input } from '../../components/Form';
import { login, socialLogin } from '../../providers/authProvider';

import { baseURL } from '../../providers/config';
import { useDispatch, useSelector } from 'react-redux';
import logo from "../../assets/landpage/escudo.png";
import './styles.css';

const initialPayload = {
  username: "",
  password: "",
}

const Login = props => {

  const history = useHistory();
  const [payload, setPayload] = useState(initialPayload);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const isLogged = useSelector((state) => state.auth.isLogged);
  const dispatch = useDispatch();

  const onChangePayload = e => {
    setPayload({...payload, [e.target.name]: e.target.value});
  }

  const isValid = () => {
    if(Object.values(payload).some(value => !value)) {
      setError("Preencha todos os campos para entrar");
      return false;
    }
    return true;
  }

  const send = async e => {
    e.preventDefault();
    if(!isValid()) {
      return;
    }
    setLoading(true);
    setError("")


    const result = await dispatch(login(payload));
    setLoading(false);
    if(result.error) {
      setError("E-mail ou senha incorretos");
      return;
    }
  }

  const FBLogin = e => {
    e.preventDefault();
    setError("")
    
    window.FB.login(function(response) {
      if (response.status === 'connected') {
        // Logged into your webpage and Facebook.
        window.FB.api("/me", "GET", {"fields": "name,email"}, (response) => {
          console.log(response);
          dispatch(socialLogin({
            social_id: response.id,
            email: response.email,
            name: response.name,
          })).then(data => {
            if(data.error) {
              setError("Não foi possível entrar com sua conta do Facebook");
              return;
            }
          }).catch(error => {
            console.log("error:", error);
          });
        })
      } else {
        // The person is not logged into your webpage or we are unable to tell. 
        console.log("não entrou");
      }
    }, {scope: 'public_profile,email'});
  }

  if(isLogged) {
    history.push("/app/usuario");
    return null;
  }

  return (
    <div className="login-screen">
      <main>

        <img className="app-logo" src={logo} alt="Logo Bolão Zé do Bet" />
        <h1>Bolão Zé do Bet</h1>

        <form>

          <Input type="email" placeholder="Usuário/Email" name="username" value={payload.username} onChange={onChangePayload} />
          <Input type="password" placeholder="Senha" name="password" value={payload.password} onChange={onChangePayload} />
          <a 
            //to="/recuperar-senha" 
            href={`${baseURL}/accounts/password_reset/`}
            target="_blank"
            className="recover-password-link"
          >
            Esqueceu sua senha?
          </a>

          <Alert show={error}>{error}</Alert>

          <SubmitButton disabled={loading} onClick={send}>
            {loading ? "Carregando..." : "Entrar"}
          </SubmitButton>

          <FacebookButton onClick={FBLogin}>
            Continuar com o Facebook
          </FacebookButton>

          <Link to="/termos">Termos, condições e regras</Link>

        </form>

        <Link to="/cadastro">Não tem conta? Cadastre-se agora!</Link>

      </main>
      
    </div>
  )
}

export default Login;
