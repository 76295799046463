import axios from "./config";
import { editUserSuccess, loginSuccess } from "../store/ducks/auth";

export const register = payload => async dispatch => {
  try {
    const result = await axios.post("/user/", payload);
    dispatch(loginSuccess(result.data));
    return { error: false, data: result.data };
  } catch (error) {
    console.log(error.response);
    return { error:true, message:error.response }
  }
}

export const login = payload => async dispatch => {

  try {
    const result = await axios.post("/authentication/", payload);
    dispatch(loginSuccess(result.data));
    return { error: false, data: result.data };
  } catch (error) {
    console.log(error.response);
    return { error:true, message:error.response }
  }
}

export const socialLogin = payload => async dispatch => {

  try {
    const result = await axios.post("/user/authentication/social/", payload);
    dispatch(loginSuccess(result.data));
    return { error: false, data: result.data };
  } catch (error) {
    console.log(error.response);
    return { error:true, message:error.response }
  }
}

export const editUser = (id, payload, token) => async dispatch => {

  const config = {
    headers: { Authorization: `Token ${token}` }
  };

  try {
    const result = await axios.patch(`/user/${id}/`, payload, config);
    dispatch(editUserSuccess(result.data));
    return { error: false, data: result.data };
  } catch (error) {
    const { status, data } = error.response;
    if(status === 400 && data.hasOwnProperty('taxpayer')) {
      return { error:true, message: data.taxpayer[0] }
    }
    return { error:true, message: "Não foi possível editar suas informações" }
  }
}

export const uploadProfilePhoto = (data, userId, token) => async dispatch => {
  try {
    const formData = new FormData();

    formData.append("photo", data);
    formData.append('_method', 'PATCH');

    const config = {
      headers: { Authorization: `Token ${token}` }
    };

    const result = await axios.patch(`/user/${userId}/`, formData, config);

    dispatch(editUserSuccess(result.data));

    return {error: false, data: result.data};
  } catch (error) {
    console.log(error);
    return { error: true };
  }
};
