import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AppContainer from "./containers/AppContainer";
import LandingPage from "./screens/LandingPage";

import Login from "./screens/Login";
import RecoverPassword from "./screens/RecoverPassword";
import Register from "./screens/Register";
import Terms from "./screens/Terms";
import Policy from './screens/Policiy';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/landing" />
        </Route>
        <Route path="/landing" exact>
          <LandingPage />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/recuperar-senha" exact>
          <RecoverPassword />
        </Route>
        <Route path="/cadastro" exact>
          <Register />
        </Route>
        <Route path="/termos" exact>
          <Terms />
        </Route>
        <Route path="/policy" exact>
          <Policy />
        </Route>
        <Route path="/app">
          <AppContainer />
        </Route>
      </Switch>
    </Router>
  )
}

export default Routes;
