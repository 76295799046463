import React, { useEffect, useState } from 'react';

import './styles.css';
import { OutlineButton, SubmitButton } from '../../components/Button';
import UserAvatar from '../../components/UserAvatar';
import { getTournaments, subscribeToTournament } from '../../providers/betsProvider';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../../components/Form';
import showToast, { SUCCESS } from '../../utils/Toast';
import Spinner from '../../components/LoadingSpinner';

const Tournaments = props => {
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const [tournaments, setTournaments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selected, setSelected] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState("");

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const result = await getTournaments();
      setLoading(false);
      if(result.error) {
        setError("Ocorreu um erro e não foi possível exibir os torneios");
        return;
      }
      setTournaments(result);
    }
    getData();
  }, [])

  const send = async () => {
    const _currentDate = new Date();
    const _tournamentDate = new Date(selected.tournament.deadline);

    if(_currentDate > _tournamentDate) {
      setErrorSubmit("O periodo de inscrição para esse Torneio acabou");
      return ;
    }

    
    setLoadingSubmit(true);
    setErrorSubmit("");


    const result = await dispatch(subscribeToTournament({
      user: user.id,
      sub: selected.id,
    }, user.token)); 

    setLoadingSubmit(false);
    
    if(result.error) {
      if(result.message) {
        setErrorSubmit(result.message);
        return ;
      }
      setErrorSubmit("Ocorreu um erro ao se inscrever no torneio");
      return;
    }
    showToast("Inscrição realizada com sucesso", SUCCESS);
    backToChoices();
  }

  const toConfirm = () => {
    if(!selected) return;
    setConfirm(true);
  }

  const backToChoices = () => {
    setConfirm(false);
    setSelected(null);
    setErrorSubmit("");
  }

  const renderChoices = () => (
    <div className="tournaments-content">
      <div className="choices-container">
        <h1>Seleção Torneios</h1>
        {loading ? <Spinner size="large" /> : 
        tournaments.map(item => (
          <button 
            disabled={isSubscripted(item, user.subscriptions)}
            key={`tournament-${item.id}`}
            className={`choice-btn ${getTournamentClassName(item.level.name)} ${selected && selected.id === item.id ? "selected" : null} ${tournamentChoiceDisabled(item, user.subscriptions)}`}
            onClick={() => setSelected(item)}
          >
            {item.level.name} <br/>
            <span className="light">{item.tournament.name} </span> <br/>
            Z$ {item.level.registration_fee}
          </button>
        ))
        }
      </div>

      <div className="confirmation-container">
        <h2 className="confirmation-title">Confirmação inscrição no torneio</h2>
        <SubmitButton onClick={toConfirm}>Confirma</SubmitButton>
      </div>
    </div>
  )

  const renderConfirmation = () => (
    <>
      <div className="tournament-corfirmation">
        <section>
          <h2>{selected.level.name} - {selected.tournament.name}</h2>
          <ul className="tournament-info">
            <li>Z$ {selected.level.registration_fee}</li>
            <li>Premiação: Z$ {selected.prize}</li>
            <li>Participantes: {selected.number_participants}</li>
            <li>Aposte até {new Date(selected.tournament.deadline).toLocaleDateString('pt-br', {hour: 'numeric', minute:'numeric'})}</li>
          </ul>
        </section>
      </div>
      <Alert show={errorSubmit}>{errorSubmit}</Alert>
      <div className="button-container">
        <SubmitButton onClick={send}>
          {loadingSubmit ? 'Aguarde...' : 'Confirmar'}
        </SubmitButton>
        <OutlineButton className="light-border" onClick={backToChoices}>Cancelar</OutlineButton>
      </div>
    </>
  )

  return (
    <>
      <aside>
        <UserAvatar image="https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg" />

        {/* <p className="user-name">{user.name}</p> */}
        <p className="page-helper-text">Selecione qual nível de sorteio você deseja participar ao lado.</p>
      </aside>
      <main className="tournaments-screen">
 
        {confirm ? renderConfirmation() : renderChoices()}

      </main>
    </>
  )
}

const getTournamentClassName = (type) => {
  switch (type) {
    case "High":
      return "high";

    case "Mid":
      return "mid";
  
    default:
      return "low";
  }
}

const isSubscripted = (tournament, subscriptions) => {
  if(subscriptions) {
    return subscriptions.some(subscription => subscription.sub.id === tournament.id);
  }
}

const tournamentChoiceDisabled = (tournament, subscriptions) => {
  return isSubscripted(tournament, subscriptions) ? "disabled" : "";
}


export default Tournaments;
