import styled from "styled-components";

const RankingItem = styled.li`

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  --margins: .5rem;

  margin-bottom: var(--margins);
  padding: var(--margins);

  --gradient-color-start: ${({prize = false}) => prize ? "#6D182F" : "rgba(255,162,0,1)"};
  --gradient-color-stop: ${({prize = false}) => prize ? "#D92F2F" : "#D92F5E"};
  --stop-gradient: ${({ score, highScore }) => `${(score / highScore) * 100}%`};

  background: linear-gradient(90deg, var(--gradient-color-start) 0%, var(--gradient-color-stop) var(--stop-gradient), rgba(0,0,0,0) var(--stop-gradient), rgba(0,0,0,0) 100%);


  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: var(--margins);
  }

  .ranking-item-user {
    display: flex;
    align-items: center;
    font-size: 1.175rem;
  }

  .ranking-item-points {
    font-weight: bold;
    font-size: 1.25rem;
  }

  @media (min-width: 1400px) {
    img {
      width: 40px;
      height: 40px;
    }
  }

`

export default RankingItem;
