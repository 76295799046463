import React, { useState } from "react";
import { Route, NavLink } from "react-router-dom";
import { ImageButton } from "../../components/Button";

import "./styles.css";
import menuIcon from "../../assets/icons/menu.png";

import UserAvatar from "../../components/UserAvatar";
import UserProfile from "../../screens/UserProfile";
import Notifications from "../../screens/Notifications";
import FAQ from "../../screens/FAQ";
import Transactions from "../../screens/Transactions";
import Contact from "../../screens/Contact";
import { logout } from "../../store/ducks/auth";
import { useDispatch, useSelector } from "react-redux";
import { uploadProfilePhoto } from "../../providers/authProvider";
import showToast, { SUCCESS } from "../../utils/Toast";

const routes = [
  { name: "Meus dados", path: '/app/usuario', component: UserProfile, exact: true },
  { name: "Notificações", path: '/app/usuario/notificacoes', component: Notifications, exact: true },
  // { name: "Mensagens", path: '/app/usuario/mensagens', component: () => null, exact: true },
  // { name: "Ajuda", path: '/app/usuario/ajuda', component: () => null, exact: true },
  { name: "Suporte", path: '/app/usuario/suporte', component: FAQ, exact: true },
  { name: "Extrato", path: '/app/usuario/extrato', component: Transactions, exact: true },
  { name: "Contato", path: '/app/usuario/contato', component: Contact, exact: true },
];

const UserContainer = props => {

  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  let asideClasses = "user-menu";
  if(menuOpen) {
    asideClasses += " open";
  }

  const sendPicture = async (e) => {
    const picture = e.target.files[e.target.files.length - 1];

    if (!picture) return;

    const result = await dispatch(uploadProfilePhoto(picture, user.id, user.token));
    if(result.error) {
      showToast("Não foi possivel atualizar Avatar");
      return ;
    }
    showToast("Avatar atualizado com sucesso", SUCCESS);
  }

  return (
    <>
      <aside className={asideClasses}>
        <label className="avatar-label">
          <UserAvatar
            className="avatar"
            image="https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
          />
          <input type="file" onChange={sendPicture}/>
        </label>
        <h2 className="nav-title">Geral</h2>
        <nav className="user-links-nav">

          {routes.map(route => (
            <NavLink
              key={route.path}
              to={route.path}
              onClick={() => setMenuOpen(false)}
              className="user-link"
            >
              {route.name}
            </NavLink>
          ))}
          <NavLink
            to={"/"}
            onClick={() => dispatch(logout())}
            className="user-link"
          >
            Sair
          </NavLink>

        </nav>

        <h2 className="about-title">Sobre</h2>
        <div className="about-content">
          <span>Versão</span><span>v 1.0</span>
        </div>
      </aside>

      <ImageButton 
        className="menu-button" 
        icon={menuIcon}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        Menu
      </ImageButton>

      {/* Pages must have a <main> container */}
      {routes.map(route => (
        <Route key={route.path} {...route} />
      ))}
    
    </>
  )
}

export default UserContainer;
