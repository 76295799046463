import React, { useEffect, useState } from 'react';

import playstore from "../../assets/playstore.png";
import applestore from "../../assets/applestore.png";
import Header from './header/Header';
import MainSection from './main/mainSection';
import Footer from './Footer';
import BetSection from './bets';
import Partner from './partner';
import Payment from './Payment';
import Tournaments from './Tournaments';

const LandingPage = props => {

  return (
    <div className="landing-screen">
      <Header />
      <MainSection />
      <BetSection />
      <Tournaments />
      <Payment />
      <Partner />
      <Footer />
    </div>
  )
}

export default LandingPage;
