import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useDispatch, useSelector } from 'react-redux';

import NumberFormat from 'react-number-format';
import showToast, { SUCCESS } from '../../utils/Toast';
import { Alert, FormatUnderlinedInput, UnderlinedInput } from '../../components/Form';
import { OutlineButton, SubmitButton } from '../../components/Button';
import { editUser } from '../../providers/authProvider';
import { getAddressCEP, getTeams } from '../../providers/userServicesProvider';
import { validCpf } from '../../utils/validations';
import StateJson from '../../utils/states.json';
import './styles.css';
import Modal from '../../components/Modal/Modal';

const UserProfile = props => {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.auth.user);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [form, setForm] = useState({
    name: "",
    email: "",
    taxpayer: "",
    phone: "",
    city: "",
    favorite_team: "",
    postal_code: ""
  });
  const [changes, setChanges] = useState({});

  useEffect(() => {
    setForm({
      name: userInfo.name,
      email: userInfo.email,
      taxpayer: userInfo.taxpayer,
      phone: userInfo.phone,
      city: userInfo.city,
      state: userInfo.state,
      favorite_team: userInfo.favorite_team ? { value: userInfo.favorite_team, label: userInfo.favorite_team.name } : {},
      neighborhood: userInfo.neighborhood,
      postal_code: userInfo.postal_code,
      street: userInfo.street
    });
  }, [userInfo]);

  useEffect(() => {
    if (form.postal_code && form.postal_code.length === 8) {
      loadCEP();
    }
  }, [form.postal_code]);

  const loadCEP = async () => {
    setLoading(true);
    const result = await getAddressCEP(userInfo.token, form.postal_code);
    setLoading(false);
    if (result.error) {
      const { message } = result.message.data;
      showToast(message);
    } else {
      const _state_name = StateJson.estados.find(item => item.sigla == result.uf)?.nome;
      setForm({
        ...form,
        neighborhood: result.bairro,
        city: result.cidade,
        state: _state_name ? _state_name : '',
        street: result.logradouro
      });
      setChanges({
        ...changes,
        neighborhood: result.bairro,
        city: result.cidade,
        state: _state_name ? _state_name : '',
        street: result.logradouro
      });
    }
  }

  const handleChange = e => {
    setForm({
      ...form,
      city: e.target.name === 'state' ? '' : form.city,
      [e.target.name]: e.target.value
    });
    setChanges({
      ...changes,
      city: e.target.name === 'state' ? '' : form.city,
      [e.target.name]: e.target.value
    });
  };

  const send = async e => {
    e.preventDefault();
    setShowModal(false);
    if (loading) return;

    if (changes.hasOwnProperty('taxpayer')) {
      if (!validCpf(changes.taxpayer)) {
        showToast('O CPF deve ser válido');
        return;
      }
    }

    setLoading(true);
    setError("");

    const result = await dispatch(editUser(userInfo.id, changes, userInfo.token));
    setLoading(false);
    if (result.error) {
      setError(result.message);
      return;
    }

    setChanges({});
    showToast('Dados Atualizados!', SUCCESS);
  }

  const setFavoriteTeam = (value) => {
    setForm({
      ...form,
      favorite_team: value
    });
    setChanges({
      ...changes,
      favorite_team: value.value.id
    });
  }

  const loadTeamsOptions = async (search, loadedOptions) => {
    const result = await getTeams(userInfo.token, 1, search);
    const options = result.results.map(item => ({ value: item, label: `${item.name}` }))
    return {
      options: options,
      hasMore: result.next ? true : false,
      additional: {
        page: 1,
      },
    };
  }

  return (
    <main className="user-profile-screen">

      <h1>Meus dados</h1>

      <form>
        <div className="general-info">
          <label>
            Nome
            <UnderlinedInput
              disabled
              value={form.name} name="name" />
          </label>
          <label>
            E-mail
            <UnderlinedInput type="email" value={form.email} name="email" onChange={handleChange} />
          </label>
          <label>
            CPF
            <FormatUnderlinedInput
              disabled={userInfo.taxpayer} format="###.###.###-##"
              type="tel" value={form.taxpayer} name="taxpayer"
              onValueChange={({ value }) => {
                setForm({
                  ...form,
                  taxpayer: value
                });
                setChanges({
                  ...changes,
                  taxpayer: value
                });
              }} />
          </label>
          <label>
            Telefone
            <FormatUnderlinedInput
              type="tel" value={form.phone} name="phone"
              format="(##) #####-####"
              onValueChange={({ value }) => {
                setForm({
                  ...form,
                  phone: value
                });
                setChanges({
                  ...changes,
                  phone: value
                });
              }} />
          </label>
          <label>
            Estado
            <UnderlinedInput
              value={form.state} list="states" name="state" id="state"
              onChange={handleChange} />
            <datalist id="states">
              {StateJson.estados.map((item, index) => (
                <option value={item.nome}>{item.nome}</option>
              ))}
            </datalist>
          </label>
          <label>
            Cidade
            <UnderlinedInput
              value={form.city} list="cities" name="city"
              id="city" onChange={handleChange} />
            <datalist id="cities">
              {StateJson.estados.find((item) => item.nome === form.state)?.cidades.map((item, index) => (
                <option value={item}>{item}</option>
              ))}
            </datalist>
          </label>
          <label>
            Bairro
            <UnderlinedInput
              value={form.neighborhood} name="neighborhood" onChange={handleChange} />
          </label>
          <label>
            CEP
            <FormatUnderlinedInput format="#####-###"
              value={form.postal_code} name="postal_code"
              onValueChange={({ value }) => {
                setForm({
                  ...form,
                  postal_code: value
                });
                setChanges({
                  ...changes,
                  postal_code: value
                });
              }} />
          </label>
          <label>
            Rua
            <UnderlinedInput
              value={form.street} name="street" onChange={handleChange} />
          </label>
        </div>

        <div className="favorite-info">
          {form.favorite_team?.value ? (
            <img src={form.favorite_team?.value?.crest} alt={`Escudo de nome do ${form.favorite_team?.value?.name}`} />
          ) : null}
          <label>
            Time Favorito
            <AsyncPaginate
              value={form.favorite_team}
              loadOptions={loadTeamsOptions}
              onChange={setFavoriteTeam}
              placeholder=""
              className='react-select-container'
              additional={{
                page: 1,
              }}
            />
          </label>
        </div>

      </form>

      <Alert className="alert-msg" show={error}>{error}</Alert>

      <SubmitButton
        disabled={loading || Object.keys(changes).length < 1}
        onClick={(event) => {
          if (changes.taxpayer) {
            setShowModal(true);
          } else {
            send(event);
          }
        }}>
        {loading ? "Salvando..." : "Salvar"}
      </SubmitButton>
      <Modal toggle={() => setShowModal(!showModal)} isOpen={showModal}>
        <div className="profileModalContainer">
          <h3>Atenção!</h3>
          <h5>Ao salvar o CPF você só poderar alterar mediante ticket, confirme se os dados estão corretos.</h5>
          <SubmitButton onClick={send}>Salvar</SubmitButton>
          <OutlineButton onClick={() => setShowModal(false)}>Cancelar</OutlineButton>
        </div>
      </Modal>
    </main>

  )
}

export default UserProfile;
