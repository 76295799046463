import { addSubscription } from "../store/ducks/auth";
import axios from "./config";

export const getTournaments = async () => {
  // const config = {
  //   headers: { Authorization: `Token ${token}` }
  // };
  try {
    const result = await axios.get(
      `/tournament_level/?tournament__status=ATIVA`
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

export const subscribeToTournament = (payload, token) => async dispatch => {

  const config = {
    headers: { Authorization: `Token ${token}` }
  };

  try {
    const result = await axios.post(`/subscription/`, payload, config);
    dispatch(addSubscription(result.data));
    return { error: false, data: result.data };
  } catch (error) {
    return { error: true, message: error.response.data.message }
  }
}

export const getSubscriptions = async (userId, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/subscription/?sub__tournament__status=ATIVA&user=${userId}`,
      config
    );

    return result.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

export const getMatches = async (token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/match/`,
      config
    );

    return result.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

export const getGuesses = async (subscription, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/guess/?match__game_round__status=ATIVA&subscription=${subscription}`,
      config
    );

    return result.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

export const editGuess = async (id, payload, token) => {

  const config = {
    headers: { Authorization: `Token ${token}` }
  };

  try {
    const result = await axios.patch(`/guess/${id}/`, payload, config);
    return { error: false, data: result.data };
  } catch (error) {
    console.log(error.response);
    return { error: true, message: error.response }
  }
}

export const getActiveTournaments = async (token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/tournament_level/?tournament__status=ATIVA`
    );

    return result.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

export const getRanking = async (tornamentLevelId, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/point/ranking/?page=1&subscription__sub=${tornamentLevelId}`,
      config
    );

    return result.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

export const getUserRanking = async (userId, tornamentLevelId, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/point/ranking/?page=1&subscription__user=${userId}&subscription__sub=${tornamentLevelId}`,
      config
    );

    return result.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

export const getListRanking = async (user, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/ranking/tournament/?user=${user}`,
      config
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

export const getPrizes = async (tornamentLevelId, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/value/ranking/?page=1&subscription__sub=${tornamentLevelId}`,
      config
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

export const getUserPrize = async (userId, tornamentLevelId, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/value/ranking/?page=1&subscription__user=${userId}&subscription__sub=${tornamentLevelId}`,
      config
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

export const getGuessesByTournament = async (token, subscriptionId, tornamentId) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/guess/?subscription=${subscriptionId}&subscription__sub__tournament=${tornamentId}`,
      config
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

// ### MyTournaments ###

export const getUserTournaments = async(token, userId) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(`/tournament/user/?user=${userId}`, config);
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

export const getMyTournament = async(token, userId, tournamentId) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(`/tournament_level/user/?user=${userId}&tournament=${tournamentId}`, config);
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}