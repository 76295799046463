import React, { useEffect, useState } from 'react';

import './styles.css';
import { OutlineButton, SubmitButton } from '../../components/Button';
import UserAvatar from '../../components/UserAvatar';
import { editGuess, getActiveTournaments, getGuesses, getSubscriptions } from '../../providers/betsProvider';
import { useSelector } from 'react-redux';
import { getHourAndMinutes, getWeekDay } from '../../utils/dateUtils';
import * as Tabs from '../../components/Tabs/Tabs';
import { Alert, RadioLabel } from '../../components/Form';
import ImportBetsModal from '../../components/ImportBetsModal/ImportBetsModal';
import showToast, { SUCCESS } from '../../utils/Toast';
import Spinner from '../../components/LoadingSpinner';

const Bets = props => {

  const user = useSelector(state => state.auth.user);
  const [matches, setMatches] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [currentSubscription, setCurrentSubscription] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [errorEditing, setErrorEditing] = useState("");
  const [jokerMatch, setJokerMatch] = useState(null);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [canBet, setCanBet] = useState(true);

  useEffect(() => {
    const deadline = currentSubscription?.sub?.tournament?.deadline;

    if(!deadline) return;

    new Date() < new Date(deadline) ? setCanBet(true) : setCanBet(false);

  }, [currentSubscription])

  useEffect(() => {
    if(!user.token) return;
    const getData = async () => {
      const subscriptions = user.subscriptions;
      
      if(subscriptions === undefined || subscriptions.length < 1) {
        setErrorEditing("Você não esta inscrito em um Torneio no momento");
        return ;
      }
      
      setTournaments(subscriptions);
      
      if(subscriptions[0]) {
        setCurrentSubscription(subscriptions[0]);
        setLoading(true);
        const guesses = await getGuesses(subscriptions[0].id, user.token);
        setLoading(false);
        
        if(!guesses.error) {
          
          setMatches(guesses);
          const bonus = guesses.find(guess => guess.bonus);
          if(bonus)
            setJokerMatch(bonus.id);
        }
      }
    }
    getData();
  }, [user.token])

  // console.log(user)
  
  const onChangeTab = async (tab) => {
    if(tab === activeTab) return;
    setActiveTab(tab);
    setMatches([]);
    setJokerMatch(null);
    setCurrentSubscription(tournaments[tab - 1]);

    const guesses = await getGuesses(tournaments[tab - 1].id, user.token);
    
    if(!guesses.error) {
      setMatches(guesses);
      const bonus = guesses.find(guess => guess.bonus);
      if(bonus)
        setJokerMatch(bonus.id);
    }
  }

  const onChangeGuess = async (guessId, team, newGuess) => {
    if(!canBet) return;
    
    setErrorEditing("");
    const newList = matches.map(guess => {
      if(guess.id === guessId) {
        return {
          ...guess,
          [team]: newGuess,
        }
      }
      return guess;
    });
    setMatches(newList);

    if(newGuess === "") {
      return;
    }

    const payload = {
      [team]: newGuess,
    }

    const result = await editGuess(guessId, payload, user.token);
    if(result.error) {
      //unmake change
      setMatches(matches);
      //set error message
      setErrorEditing("Houve um problema ao alterar o placar. Recarregue a página e verifique se seus palpites foram salvos corretamente");
      return;
    }
    showToast('Palpite atualizado', SUCCESS);

  }

  const onChangeJoker = async e => {
    if(!canBet) return;
    const targetId = e.target.name;
    if(targetId == jokerMatch) {
      //remove bonus
      setJokerMatch(null);
      const result = await editGuess(targetId, {bonus:false}, user.token);
      if(result.error) {
        //unmake change
        //setMatches(matches); // update list?
        //set error message
        setErrorEditing("Houve um problema ao alterar o placar. Recarregue a página e verifique se seus palpites foram salvos corretamente");
        return;
      }
      showToast('Palpite coringa atualizado', SUCCESS);
      return;
    }

    const oldJokerMatch = jokerMatch
    setJokerMatch(targetId)
    //remove bonus from current
    if(oldJokerMatch) {
      editGuess(oldJokerMatch, {bonus:false}, user.token)
        .then(result => {
          
        })
    }
    
    //add new bonus
    editGuess(targetId, {bonus:true}, user.token)
      .then(result => {
        showToast('Palpite coringa atualizado', SUCCESS);
      })

  }

  const onImportSuccess = async () => {
    setMatches([]);

    const guesses = await getGuesses(currentSubscription.id, user.token);
    
    if(!guesses.error) {
      setMatches(guesses);
    }
    setTimeout(() => setImportModalOpen(false), 800);
  }

  return (
    <>
      <aside>
        <UserAvatar image="https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg" />

        <p className="user-name">{user.name}</p>
        <p className="page-helper-text">Faça seus palpites e ganhe pontos ao acertar os placares dos jogos.</p>
      </aside>
      <main className="bets-screen">

        <h1>Palpites</h1>

        <Tabs.default>
          <Tabs.TabList>
            {tournaments.map((item, index) => (
              <Tabs.Tab 
                active={activeTab == index + 1} 
                onClick={() => onChangeTab(index + 1)}
                key={`tab-${item.id}`}
              >
                {item.sub.level.name}
              </Tabs.Tab>
            ))}
          </Tabs.TabList>
        </Tabs.default>

        {tournaments.length > 1 && canBet ? (
          <OutlineButton className="import-btn" onClick={() => setImportModalOpen(true)}>
            Importar palpites
          </OutlineButton>
        ) : null}

        <Alert className="feedback-msg" show={!canBet}>Não é mais possível realizar palpites nessa rodada</Alert>

        <Alert className="feedback-msg" show={errorEditing}>{errorEditing}</Alert>

        {loading ? <Spinner size="large"/> : <ul className="bets-content">
          {matches.map(guess => (
            <li className="bet-item" key={`guess-${guess.id}`}>
              <div className="bet-content">
                <div className="team-info">
                  <img src={guess.match.home_team.crest} alt={guess.match.home_team.name} />
                  {guess.match.home_team.name}
                </div>
                <div className="game-info">
                  <h2>{guess.match.game_round.name}</h2>
                  <p className="game-date">{getWeekDay(guess.match.date_in)} - {getHourAndMinutes(guess.match.date_in)}</p>
                  <div className="score-inputs-container">
                    <input className="score-input" type="number" 
                      value={guess.home_team_score}
                      onChange={(e) => onChangeGuess(guess.id, "home_team_score", e.target.value)}
                    />
                    x
                    <input className="score-input" type="number" 
                      value={guess.away_team_score}
                      onChange={(e) => onChangeGuess(guess.id, "away_team_score", e.target.value)}
                    />
                  </div>
                </div>
                <div className="team-info">
                  <img src={guess.match.away_team.crest} alt={guess.match.away_team.name} />
                  {guess.match.away_team.name}
                </div>
              </div>
              <div className="joker-content">
                <RadioLabel>
                  <input type="radio" name={guess.id} checked={guess.id == jokerMatch} onClick={onChangeJoker} onChange={() => {}}/>
                  <span>Partida coringa</span>
                </RadioLabel>
              </div>
            </li>
          ))}

        </ul>}

        <ImportBetsModal
          isOpen={importModalOpen}
          toggle={() => setImportModalOpen(!importModalOpen)}
          currentSubscription={currentSubscription}
          subscriptions={tournaments}
          onSuccess={onImportSuccess}
        />

      </main>
    </>
  )
}

export default Bets;
