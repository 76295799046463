import React, { useEffect, useState } from 'react';

import './styles.css';
import UserAvatar from '../../components/UserAvatar';
import RankingItem from './RankingItem';
import { useSelector } from 'react-redux';
import { getListRanking, getActiveTournaments } from '../../providers/betsProvider';
import * as Tabs from '../../components/Tabs/Tabs';
import Spinner from '../../components/LoadingSpinner';
import UserBetsModal from '../../components/UserBetsModal';

const Ranking = props => {

  const user = useSelector(state => state.auth.user);
  const [ranking, setRanking] = useState([]);
  const [myRanking, setMyRanking] = useState({});
  const [prizes, setPrizes] = useState([]);
  const [myPrize, setMyPrize] = useState({});
  const [tournaments, setTournaments] = useState({ points: [], prizes: [] });
  const [tournamentLevels, setTournamentLevels] = useState([]);
  const [tournamentInfo, setTournamentInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [userSelected, setUserSelected] = useState(null);

  useEffect(() => {
    if (!user.token) return;
    const getData = async () => {
      setLoading(true);
      const points = await getListRanking(user.id, user.token);
      console.log(points)
      const prizes = points;
      setLoading(false);
      if (points.error) {
        setError("Não foi possível buscar os torneios");
        return;
      }

      if (points.length > 0 && prizes.length > 0) {
        setTournaments({ points, prizes });
        setRanking(points[0].ranking);
        setPrizes(prizes[0].ranking);
        setMyRanking(points[0].user ? points[0].user : {});
        setMyPrize(prizes[0].user ? prizes[0].user : {});
      }
      const result = await getActiveTournaments(user.token);
      setTournamentLevels(result);
      doSetTournamentInfo(points[0], result);
    }
    getData();
  }, [user.token])

  const doSetTournamentInfo = (currentTab = tournaments.points[activeTab], list = tournamentLevels) => {
    // console.log(list, currentTab);
    const currentTournament = list
      .find(item => item.level.id === currentTab.level.id &&
        item.tournament.id === currentTab.tournament.id)

    setTournamentInfo(currentTournament);
  }

  const onChangeTab = async (index) => {
    if (index === activeTab) return;
    setActiveTab(index);
    // console.log("tabindex", index);
    // console.log(tournaments.points);
    setRanking(tournaments.points[index].ranking);
    setPrizes(tournaments.prizes[index].ranking);
    setMyRanking(tournaments.points[index].user ? tournaments.points[index].user : {});
    setMyPrize(tournaments.prizes[index].user ? tournaments.prizes[index].user : {});
    doSetTournamentInfo(tournaments.points[index]);

  }

  const displayTournamentInfo = () => {
    let _total_prize = 0;
    if(prizes && prizes.length > 0) {
      _total_prize = prizes.reduce((acc, item) => {return acc + Number(item.value)}, 0);
    } 
    if (!tournamentInfo.id) return null;
    return (
      <>
        <h3>Participantes</h3>
        <p>{tournamentInfo.number_participants}</p>

        <h3>Premiação Total</h3>
        <p>Z$ {_total_prize.toFixed(2)}</p>
      </>
    )
  }

  const displayMyRanking = () => {
    return myRanking.id ? (
      <>
        <h3>Minha Colocação</h3>
        <p>{myRanking.position}º - {myRanking.points} PTS</p>

        <h3>Minha Premiação</h3>
        <p>Z$ {myPrize.value}</p>
      </>
    ) : (
      <>
        <p>Você não está ranqueado nesse torneio</p>
      </>
    )
  }

  return (
    <>
      <aside className="ranking-screen">
        <UserAvatar image="https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg" />

        <p className="user-name">{user.name}</p>

        <div className="my-ranking">
          {displayTournamentInfo()}
          {displayMyRanking()}

        </div>

      </aside>
      <main className="ranking-screen">
        <Tabs.default>
          <Tabs.TabList>
            {tournaments.points.map((item, index) => (
              <Tabs.Tab
                active={activeTab == index}
                onClick={() => onChangeTab(index)}
                key={`tab-${item.id}`}
              >
                {item.level.name}
              </Tabs.Tab>
            ))}
          </Tabs.TabList>
        </Tabs.default>

        <div className="user-ranking">
          {displayMyRanking()}
        </div>

        {loading ? <Spinner size="large" /> : <div className="ranking-content">
          <section>
            <h2>Ranking {tournaments[activeTab] ? tournaments[activeTab].points.level.name : null}</h2>
            <ol>
              {ranking.map(item => (
                <RankingItem
                  key={`${item.subscription.user.name}-${item.points}PTS`}
                  score={item.points}
                  highScore={ranking[0].points}
                  onClick={() => setUserSelected(item)}
                >
                  <span className="ranking-item-user">
                    <img src="https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg" alt={`Foto de ${item.subscription.user.name}`} />
                    {item.subscription.user.name}
                  </span>
                  <span className="ranking-item-points">{item.points} PTS</span>
                </RankingItem>
              ))}
            </ol>
          </section>

          <section>
            <h2>Premiação {tournaments[activeTab] ? tournaments[activeTab].prizes.level.name : null}</h2>
            <ol>
              {prizes.map(item => (
                <RankingItem key={`${item.subscription.user.name}-${item.value}PTS`}
                  score={item.value}
                  highScore={prizes[0].value}
                  prize
                  onClick={() => setUserSelected(item)}
                >
                  <span className="ranking-item-user">
                    <img src="https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg" alt={`Foto de ${item.name}`} />
                    {item.subscription.user.name}
                  </span>
                  <span className="ranking-item-points">Z$ {item.value}</span>
                </RankingItem>
              ))}
            </ol>
          </section>
        </div>}

      </main>
      <UserBetsModal
        toggle={() => setUserSelected(null)}
        isOpen={userSelected}
        selected={userSelected}
        tournament={tournaments.points[activeTab]?.tournament?.id}
        level={tournaments.points[activeTab]?.level.id}
      />
    </>
  )
}

export default Ranking;
