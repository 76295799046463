import styled from "styled-components";

export const FormBoleto = styled.form`
  max-width: 65vw;

  @media(min-width: 956px) {
    max-width: 47vw;
  }

  input {
    margin-bottom: 10px;
  }
`;