import styled from 'styled-components';

export const MainContainer = styled.main`
  padding: 28px;

  .labelSelect {
    font-size: 20px;
  }

  .inforRow {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 19px;
    border-bottom: 1px solid #cecece;
    padding-bottom: 8px;
    
    span {
      font-weight: 500;
    }
  }
`;

export const SelectContainer = styled.select`
  min-width: 124px;
  height: 32px;
  padding: 0 10px 0 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: transparent;
  outline: none;
  color: white;
  border: none;
  border-bottom: 1px solid white;
  font-size: 17px;
`;

export const BackgroundWarn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;

  img {
    width: 18rem;
    align-items: center;
    object-fit: contain;
    margin-bottom: 22px;
    filter: opacity(20%);
  }
  
  p {
    font-size: 22px;
  }
`;