import NumberFormat from "react-number-format";
import styled from "styled-components";

export const Input = styled.input`
  margin-top: 1rem;
  background-color: var(--bg-secondary);
  color: var(--font-primary);
  border: none;
  outline: none;
  padding: 1rem 0.75rem;
  width: 100%;
  font-size: 1.125rem;

  ::placeholder {
    color: var(--font-secondary);
    opacity: 1;
  }

`;

export const UnderlinedInput = styled.input`
  background-color: transparent;
  color: var(--font-secondary);
  border: none;
  border-bottom: 1px solid var(--line-details);
  outline: none;
  padding-top: 0.5rem;
  width: 100%;
  font-size: 1rem;

  ::placeholder {
    color: var(--font-secondary);
    opacity: 0.8;
  }
`

export const FormatUnderlinedInput = styled(NumberFormat)`
  background-color: transparent;
  color: var(--font-secondary);
  border: none;
  border-bottom: 1px solid var(--line-details);
  outline: none;
  padding-top: 0.5rem;
  width: 100%;
  font-size: 1rem;

  ::placeholder {
    color: var(--font-secondary);
    opacity: 0.8;
  }
`;

export const UnderlinedTextarea = styled.textarea`
  background-color: transparent;
  color: var(--font-secondary);
  border: none;
  border-bottom: 1px solid var(--line-details);
  outline: none;
  padding-top: 0.5rem;
  width: 100%;
  min-height: 15ch;
  font-size: 1rem;

  ::placeholder {
    color: var(--font-secondary);
    opacity: 0.8;
  }
`

export const CheckboxContainer = styled.label`

  cursor: pointer;
  margin: 1.2rem;
  display: block;
  color: var(--font-secondary);

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {

    display: flex;
    align-items: center;
    text-align: left;

    &::before {
      content: "";
      margin-right: 1.2rem;
      flex-shrink: 0;
      height: 1.5rem;
      width: 1.5rem;
      background-color: var(--bg-secondary);
    }
  }

  input:checked ~ span::before {
    background-color: var(--action-primary);
  }
`;

export const ToggleContainer = styled.label`
  cursor: pointer;
  display: block;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  --toggle-size: 22px;
  --toggle-gap: 4px;
  --toggle-background-height: calc(var(--toggle-size) + var(--toggle-gap));
  --toggle-background-width: calc(var(--toggle-background-height) * 2);

  &::after {
    content: "";
    background-color: var(--bg-quaternary);
    display: inline-block;
    height: var(--toggle-background-height);
    border-radius: var(--toggle-background-height);
    width: var(--toggle-background-width);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {

    text-align: left;
    display: flex;
    align-items: center;
    font-size: 1.2rem;

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      transform: translateX(-100%);
      right: var(--toggle-gap);
      height: var(--toggle-size);
      width: var(--toggle-size);
      background-color: var(--bg-primary);
      border-radius: 50%;
      transition: .4s;
    }

  }

  input:checked ~ span::after {
    background-color: var(--action-primary);
    transform: translateX(0);
  }
`

export const Alert = styled.p`
  max-width: 560px;
  text-align: left;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 0.5rem 0.75rem;
  width: 100%;
  font-weight: 400;
  border-radius: 3px;
  color: #d8000c;
  background-color: #ffbaba;
  display: ${({ show = false }) => show ? "block" : "none"};
`;

export const AlertSuccess = styled(Alert)`
  color: white !important;
  background-color: green;
`;

export const RadioLabel = styled.label`
  display: block;
  /* position: relative;
  padding-left: 35px;
  margin-bottom: 12px; */
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    display: flex;
    align-items: flex-end;
  }

  span::before {
    content: "";
    /* position: absolute;
    top: 0;
    left: 0; */
    height: 1rem;
    width: 1rem;
    border: 1px solid var(--font-primary);
    border-radius: 50%;
    margin-right: 0.75rem;
  }

  input:checked ~ span::before {
    background-image: radial-gradient(circle, var(--font-primary) 0%, var(--font-primary) 55%, transparent 55%, transparent 100%);
  }

`;
