import React from "react";
import styled from "styled-components";
import { TabContainer } from "./styles";

export const TabList = styled.nav`
  min-width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr)); */
`;

export const Tab = styled.button`
  padding: 0.5rem 0.75rem;
  flex: auto;
  white-space: nowrap;
  cursor: pointer;
  color: ${({active=false}) => active ? `var(--font-primary)` : `var(--line-details)` };
  border-right: ${({active=false}) => active ? `2px solid var(--font-primary)` : `none` };
  border-bottom: ${({active=false}) => active ? `3px solid var(--font-primary)` : `2px solid var(--line-details)` };
`;

export const TabsContent = (props) => {
  return (
    <div>{props.children}</div>
  )
}

export const TabPanel = (props) => {
  return (
    <div>{props.children}</div>
  )
}

const Tabs = (props) => {
  return (
    <TabContainer>
      {props.children}
    </TabContainer>
  )
}

export default Tabs;
