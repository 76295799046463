import React from 'react';

import sosFichas from '../../../assets/landpage/sos-ze.png';
import poker from '../../../assets/landpage/p4up-ze.png';
import oneBet from '../../../assets/landpage/1xbet-ze.png';
import bodog from '../../../assets/landpage/bodog.png';

import './style.css';

const Partner = () => {

  return (
    <section className="partner-section">
      <p className="partner-title">NOSSOS PARCEIROS</p>
      <div className="partner-list">
        <div 
        onClick={() => window.open("https://sosfichas.com/", "_blank")}
        className="partner-item">
          <img className="partner-imagens" src={sosFichas} alt="parceiro 1"/>
          <p className="partner-name">FICHAS DE POKER</p>
        </div>
        <div 
        onClick={() => window.open("https://cursopoker4up.com/curso-gratuito-registration", "_blank")}
        className="partner-item">
          <img className="partner-imagens" src={poker} alt="parceiro 2"/>
          <p className="partner-name">CURSO DE POKER</p>
        </div>
        <div 
        onClick={() => window.open("https://br.1x001.com/en/registration/?tag=d_608589m_97c_", "_blank")}
        className="partner-item">
          <img className="partner-imagens" src={oneBet} alt="parceiro 3"/>
          <p className="partner-name">SPORT BETING</p>
        </div>
        <div 
        onClick={() => window.open("https://www.bodog.com/poker?overlay=join&referral=EeBBLdqvET6KJXXPnViRtGNd7ZgqdRLk&affid=23568&utm_medium=&utm_source=23568&m_id=&b_id=1&referrer=zedobet.com.br", "_blank")}
        className="partner-item">
          <img className="partner-imagens" src={bodog} alt="parceiro 4"/>
          <p className="partner-name">SITE DE POKER</p>
        </div>
      </div>
    </section>
  )
}

export default Partner;