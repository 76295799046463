import React from 'react';


import DepositoImage from '../../../assets/landpage/deposito.png';
import SaqueImage from '../../../assets/landpage/saque.png'
import './style.css';

const Payment = () => {

  return (
    <div className="payment-section">
      <p className="payment-title">PAGAMENTOS</p>
      <div className="payment-row1">
        <div className="payment-infor-container">
          <p className="payment-deposito-title">Depositos</p>
          <div className="payment-line"></div>
          <p className="payment-text">Os depósitos para a compra de fichas podem ser feitos no cartão ou no boleto, através do nosso site ou do App do bolão do Zé do Bet.</p>
          <p className="payment-text">Você compra as Zé Coins Z$ e vai usá-las para se inscrever nas rodadas semanais.</p>
        </div>
        <img className="payment-image" src={DepositoImage} alt="deposito imagem" />
      </div>
      <div className="payment-row1">
        <img className="payment-image" src={SaqueImage} alt="saque imagem" />
        <div className="payment-infor-container">
          <p className="payment-deposito-title">Saques de Prêmios</p>
          <div className="payment-line"></div>
          <p className="payment-text">O valor dos prêmios da rodadas poderão ser sacados e serão depositados em conta bancária do vencedor.</p>
          <p className="payment-text">O Jogador com a maior pontuação da rodada leva a maior premiação em dinheiro.</p>
        </div>
      </div>
    </div>
  )
}

export default Payment;