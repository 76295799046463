import React from 'react';

import logo from "../../../assets/landpage/escudo.png";
import mascote from '../../../assets/landpage/mascote.png';
import telegram from '../../../assets/landpage/telegram-icon.png';

import './style.css';

const MainSection = () => {

  return (
    <main className="landing-section">

      <div className="row1">
        <img className="mascote" src={mascote} alt="mascote ze do bet" />
        <div>
          <p className="title">ZÉ DO BET</p>
          <p className="subtitle">BOLÃO ESPORTIVO</p>
        </div>
      </div>
      
      
      <div className="row2">
        <p className="row2-title">Entre no Nosso Grupo do Telegram</p>
        <p className="row2-subtitle">Participe logo da primeira rodada e fature alto.</p>
        <img src={telegram} alt="telegram icone" />
        <a className="sub-button">QUERO ME INSCREVER</a>
      </div>

      <div className="row3">
        <p className="row3-title">O Que É O Zé do Bet</p>
        <div className="row3-line"></div>
        <div className="infor-container">
          <div className="infor-texts">
            <p>O Zé do bet é um bolão diferente onde quem ganha é sempre você.</p>
            <p>Aqui você se inscreve para rodada, pagando o buy-in (entrada do torneio) e dá seus palpites em 50 jogos daquela rodada.</p>
            <p>Os jogos são escolhidos aleatoriamente dentre os diversos Torneios de Futebol do Mundo mundo.</p>
            <p>A ideia de diversificar e colocar torneios de outros lugares é para igualar a chance dos jogadores, já que nem todos conhecerão os dados de todos os times daquela rodada.</p>
          </div>
          <img src={logo} alt="logo"/>
        </div>
      </div>

    </main>
  );
}

export default MainSection;