import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import "./styles.css";

const NavItemContent = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 15%;
  left: 0;
  bottom: 15%;
  right: 0;
  color: var(--font-secondary);
  &::before {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    background-image: ${props => `url(${props.icon})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`

const NavItem = ({to, icon, children, ...props}) => {
  return (
    <NavLink className="nav-item-container" to={to}>
      <NavItemContent icon={icon}>{children}</NavItemContent>
    </NavLink>
  )
}

export default NavItem;
