import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import logo from "../../assets/logo.png";

const AvatarContainer = styled.div`
  position: relative;
  width: 142px;
  height: 142px;
  margin: auto;
  cursor: pointer;

  &::before {
    z-index: -1;
    content: "";
    background-image: ${`url(${logo})`};
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 142px;
    height: 142px;
  }
`

const AvatarImg = styled.img`
  width: 112px;
  height: 112px;
  object-fit: cover;
  display: block;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: var(--bg-primary);
`

const UserAvatar = ({image, className}) => {
  const user = useSelector(state => state.auth.user);
  return (
    <AvatarContainer className={className}>
      <AvatarImg src={user.photo ? user.photo : image} alt="Imagem de perfil" />
    </AvatarContainer>
  )
};

export default UserAvatar;
