import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import NavItem from "../../components/NavItem";
import UserContainer from "../UserContainer";

import "./styles.css";
import homeIcon from "../../assets/icons/home.png";
import userIcon from "../../assets/icons/user.png";
import torneiosIcon from "../../assets/icons/torneios.png";
import palpitesIcon from "../../assets/icons/palpites.png";
import rankingIcon from "../../assets/icons/ranking.png";

import Bets from "../../screens/Bets";
import Ranking from "../../screens/Ranking";
import Tournaments from "../../screens/Tournaments";
import Balance from "../../screens/Balance";
import MyTournaments from '../../screens/MyTournaments';

const routes = [
  // { name: "Home", path: '/', component: () => {}, exact: true, icon: homeIcon },
  { name: "Usuário", path: '/app/usuario', component: UserContainer, exact: false, icon: userIcon },
  { name: "Caixa", path: '/app/caixa', component: Balance, exact: true, icon: "https://www.menaiortho.com.au/wp-content/uploads/2016/01/image-placeholder-300x210.png" },
  { name: "Torneios", path: '/app/torneios', component: Tournaments, exact: true, icon: torneiosIcon },
  { name: "Palpites", path: '/app/palpites', component: Bets, exact: true, icon: palpitesIcon },
  { name: "Ranking", path: '/app/ranking', component: Ranking, exact: true, icon: rankingIcon },
  { name: "Meus Torneios", path: '/app/meus-torneios', component: MyTournaments, exact: true, icon: rankingIcon },
];

const AppContainer = props => {

  useEffect(() => {
    if(window.OneSignal.showNativePrompt)
      window.OneSignal.showNativePrompt();
  }, []);

  return (
    <div className="app-container">

      {/* 
        intern pages must have this structure:
        <>
          <aside></aside>
          <main></main>
        </>
      */}

      {routes.map(route => (
        <Route key={route.path} {...route} />
      ))}

      <nav>
        {routes.map(route => (
          <NavItem
            key={`link-to-${route.path}`}
            to={route.path}
            icon={route.icon}
          >
            {route.name}
          </NavItem>
        ))}
      </nav>
    
    </div>
  )
}

export default AppContainer;
