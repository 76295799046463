import { toast } from 'react-toastify';

export const SUCCESS = 'success';

export default function showToast(content, type) {
  if(type){
    if(type === 'success') {
      toast.success(content);
    }
  } else {
    toast.error(content);
  }
};