import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ToggleInput from '../../components/Form/ToggleInput';
import { editUser } from '../../providers/authProvider';
import showToast, { SUCCESS } from '../../utils/Toast';

import './styles.css';

const initialState = {
  app_news: true,
  bet_news: true,
  match: true,
  score: true,
}

const Notifications = props => {

  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const [notificationConfig, setNotificationConfig] = useState(initialState);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  const [errorEditing, setErrorEditing] = useState({field: ""});

  useEffect(() => {
    if(!user.id) return;
    setNotificationConfig({
      app_news: user.app_news,
      bet_news: user.bet_news,
      match: user.match,
      score: user.score,
    });
  }, [user.id, user.app_news, user.bet_news, user.match, user.score]);

  const onChangeConfig = async e => {
    setErrorEditing({field: ""});
    const change = {[e.target.name]: !notificationConfig[e.target.name]}
    setNotificationConfig({...notificationConfig, ...change});
    //const result = await editNotification(notificationConfig.id, change, user.token);
    const result = await dispatch(editUser(user.id, change, user.token));

    if(result.error) {
      setNotificationConfig({...notificationConfig, [e.target.name]: notificationConfig[e.target.name]});
      //show message
      setErrorEditing({field: e.target.name});
      setTimeout(() => {
        setErrorEditing({field: ""});
      }, 3500);
      return ;
    }
    showToast('Notificações atualizadas!', SUCCESS);
  }

  return (
      <main className="notifications-screen">

        <h1>Notificações</h1>

        <div className="notifications-content">

          <div className="notification-item">
            {errorEditing.field === "app_news" ? (
              <p className="toggle-error">Não foi possível completar sua solicitação</p>
            ) : null}
            <ToggleInput name="app_news" checked={notificationConfig.app_news} onChange={onChangeConfig}>
              Novidades e avisos
            </ToggleInput>
            <p>Fique por dentro das novidades do aplicativo e avisos</p>
          </div>

          <div className="notification-item">
            {errorEditing.field === "bet_news" ? (
              <p className="toggle-error">Não foi possível completar sua solicitação</p>
            ) : null}
            <ToggleInput name="bet_news" checked={notificationConfig.bet_news} onChange={onChangeConfig}>
              Novidades do Bolão
            </ToggleInput>
            <p>Fique por dentro das novidades do Bolão e melhore seus palpites</p>
          </div>

          <div className="notification-item">
            {errorEditing.field === "match" ? (
              <p className="toggle-error">Não foi possível completar sua solicitação</p>
            ) : null}
            <ToggleInput name="match" checked={notificationConfig.match} onChange={onChangeConfig}>
              Partidas
            </ToggleInput>
            <p>Alertas para que você não esqueça de palpitar</p>
          </div>

          <div className="notification-item">
            {errorEditing.field === "score" ? (
              <p className="toggle-error">Não foi possível completar sua solicitação</p>
            ) : null}
            <ToggleInput name="score" checked={notificationConfig.score} onChange={onChangeConfig}>
              Gols
            </ToggleInput>
            <p>Alertas de gols dos jogos que você participa e sua pontuação</p>
          </div>

        </div>

      </main>
      
  )
}

export default Notifications;
