import styled from "styled-components";

export const UserBetsModalContent = styled.div`
  border-radius: 7px;
  padding: 1rem 1rem;
  margin: auto;
  min-width: 320px;
  width: 700px;
  max-width: calc(100% - 2rem);
  background-color: var(--bg-secondary);
  max-height: calc(100vh - 4rem);
  /* overflow-y: auto; */

  h4 {
    margin-top: 0;
  }

  ul.bet-list {
    max-height: 60vh;
    overflow-y: auto;
    padding: 0.75rem 0.5rem;
  }

  @media (min-width: 675px) {
    ul.bet-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
    }
  }

`;

export const BetItem = styled.li`
  background-color: var(--bg-secondary);
  box-shadow: 0px 3px 6px #00000080;
  padding: 1rem .5rem;
  margin-bottom: 1rem;

  .container-match {
    display: flex;
    justify-content: space-evenly;
    /* align-items: stretch; */
    font-size: 1rem;
    color: var(--font-secondary);
  }

  .team-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    /* padding: 1rem 0; */

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin-bottom: .25rem;
    }
  }

  .scores {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--font-primary);
    font-weight: 700;
    .score-input {
      padding: 0.5rem;
      background-color: var(--bg-primary);
      color: var(--font-primary);
      text-align: center;
      border: none;
      width: 2.25rem;
      height: 2.25rem;
      margin: 0 .25rem;
    }
  }

  .points {
    text-align: center;
  }


`