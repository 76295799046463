import styled from "styled-components";

export const BoletoModalContent = styled.div`
  border-radius: 7px;
  padding: 1.5rem;
  /* width: 320px; */
  background-color: var(--bg-secondary);

  & > h2.title {
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    margin-bottom: 1rem;
  }

  p {
    margin: 1rem 0;
    font-size: 1rem;
  }

  .feedback-msg {
    margin: 1rem 0;
  }

  .submit-btn {
    max-width: 100%;
  }

  .options {
    margin: 0.5rem 0;
  }
  .submit-btn {
    margin-top: 1.5rem;
    min-width: unset;
    padding: 0.5rem 2rem;
  }
`;

const Button = styled.button`
  cursor: pointer;
`

export const SubmitButton = styled(Button)`
  min-width: 175px;
  padding: 0.75rem 1rem;
  margin-top: 12px;
  text-transform: uppercase;
  margin: 12px auto 0 auto;
  display: block;
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
  background-color: ${({disabled}) => disabled ? 'var(--bg-quaternary)' : 'var(--action-primary)'};
`;