import React, { useEffect, useState } from 'react';

import './styles.css';
import UserAvatar from '../../components/UserAvatar';
import { getBalance, requestRelease } from '../../providers/userServicesProvider';
import { useSelector } from 'react-redux';
import { SubmitButton, WhatssapContainer } from '../../components/Button';
import { Alert, AlertSuccess, UnderlinedInput } from '../../components/Form';
import CheckoutModal from '../../components/CheckoutModal/CheckoutModal';
import ConvertModal from '../../components/ConvertModal';
import Modal from '../../components/Modal/Modal';
import ChoicePaymentModal from '../../components/ChoicePaymentModal';
import BoletoModal from '../../components/BoletoModal';

import whatssapIcon from '../../assets/icons/whatssap.png';

const betProfiles = [
  { percentage: 0.15, type: "high" },
  { percentage: 0.35, type: "mid" },
  { percentage: 0.5, type: "low" },
]

const strokeColor = (type) => {
  switch (type) {
    case "high":
      return "#D92F5E";
    case "mid":
      return "#8BC43F";
    case "low":
      return "#019AD9";
    default:
      return "#D92F5E";
  }
}

const Balance = props => {
  const user = useSelector(state => state.auth.user);

  const [balance, setBalance] = useState({});
  const [loading, setLoading] = useState(false);
  
  const [convertModal, setConvertModal] = useState(false);
  const [choiceModal, setChoiceModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [boletoModal, setBoletoModal] = useState(false);
  
  const [requestedValue, setRequestedValue] = useState("0,00");
  const [requestLoading, setRequestLoading] = useState(false);
  const [error, setError] = useState("");
  const [requestError, setRequestError] = useState("");
  const [requestSuccess, setRequestSuccess] = useState("");

  useEffect(() => {
    if (!user.id) return;
    const getData = async () => {
      setLoading(true);
      const result = await getBalance(user.id, user.token);
      setLoading(false);
      if (result.error) {
        setError("Ocorreu um erro e não foi possível exibir o caixa");
        return;
      }
      setBalance(result[0]);
    }
    getData();
  }, [user.id, user.token]);

  const isValid = () => {
    const value = Number(requestedValue.replace(",", "."));
    if (value <= 0) {
      setRequestError("Informe um valor válido");
      return false;
    }
    return true;
  }

  const sendRequest = async () => {
    const payload = {
      user: user.id,
      value: Number(requestedValue.replace(",", ".")),
    }
    if (!isValid()) return;

    const _withdraw = Number(balance.withdraw);
    if (payload.value > _withdraw) {
      setRequestError("Você não possui esse valor disponível");
      setTimeout(() => setRequestError(""), 3000);
      return;
    }

    setRequestError("");
    setRequestSuccess("");
    setRequestLoading(true);
    const result = await requestRelease(payload, user.token);
    setRequestLoading(false);
    if (result.error) {
      setRequestError('Ocorreu um problema ao realizar o pedido');
      return;
    }
    setRequestedValue('0,00');
    setRequestSuccess('Solicitação enviada com sucesso!');
    setTimeout(() => setRequestSuccess(""), 3000);
  }

  const onPaymentSuccess = async () => {
    setLoading(true);
    const result = await getBalance(user.id, user.token);
    setLoading(false);
    if (result.error) {
      setError("Ocorreu um erro e não foi possível exibir o caixa");
      return;
    }
    setBalance(result[0]);
    setPaymentModal(false);
    setConvertModal(false);
  }

  return (
    <>

      <main className="balance-screen">

        <section>
          <h2>Transações</h2>
          <ul>
            <li className="transaction-item">
              Saque <span className="red">- Z$ {balance.withdrawal}</span>
            </li>
            <li className="transaction-item">
              Depósito <span className="green">+ Z$ {balance.deposit}</span>
            </li>
            <li className="transaction-item">
              Valor gasto com inscrição <span className="blue">- Z$ {balance.amount_spent_sub}</span>
            </li>
          </ul>

          <SubmitButton id="checkout-btn" onClick={() => setChoiceModal(!choiceModal)}>
            Adicionar créditos
          </SubmitButton>

        </section>

        <section>
          <h2>Solicitar saque</h2>
          <label>
            Valor
            <UnderlinedInput
              value={`Z$ ${requestedValue}`}
              onChange={e => setRequestedValue(e.target.value.substr(3))}
            />
          </label>

          <Alert className="feedback-msg" show={requestError}>{requestError}</Alert>
          <AlertSuccess className="feedback-msg" show={requestSuccess}>{requestSuccess}</AlertSuccess>

          <SubmitButton onClick={sendRequest}>
            {requestLoading ? 'Carregando...' : 'Solicitar'}
          </SubmitButton>

          <WhatssapContainer>
            <img 
            onClick={() => {
              // window.open("https://api.whatsapp.com/send?phone=558599910680", '_blank')
            }} 
            classname="whats-image" src={whatssapIcon} alt="whatssap icone" />
            <h3>Suporte</h3>
          </WhatssapContainer>
        </section>

      </main>
      <aside className="balance-screen">
        <UserAvatar className="user-info" image="https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg" />

        <p className="user-name user-info">{user.name}</p>

        <div className="my-balance">
          <h1>Caixa/Depósito</h1>

          <div className="cash-resume balance">
            <h2>Saldo</h2>
            <p className="available-label">Saldo disponível</p>
            <p className="available-value">Z$ {balance.balance}</p>
          </div>

          <div className="convert-button" onClick={() => setConvertModal(true)}>
            CONVERTER SAQUE EM SALDO
          </div>

          <div className="cash-resume withdraw">
            <h2>Saque</h2>
            <p className="available-label">Valor disponível</p>
            <p className="available-value">Z$ {balance.withdraw}</p>
          </div>


        </div>

      </aside>

      <ChoicePaymentModal 
      isOpen={choiceModal} 
      toggle={() => setChoiceModal(!choiceModal)} 
      openCreditCard={() => {
        setChoiceModal(!choiceModal);
        setPaymentModal(!paymentModal);
      }}
      openBoleto={() => {
        setChoiceModal(!choiceModal);
        setBoletoModal(!boletoModal);
      }} />
      <CheckoutModal onSuccess={onPaymentSuccess} isOpen={paymentModal} toggle={() => setPaymentModal(!paymentModal)} />
      <ConvertModal onSuccess={onPaymentSuccess} isOpen={convertModal} toggle={() => setConvertModal(!convertModal)} />
      <BoletoModal isOpen={boletoModal} toggle={() => setBoletoModal(!boletoModal)} />
    </>
  )
}

export default Balance;



const renderDonutChart = () => {
  let chart = [];
  let endedLast = 0;
  for (let i = 0; i < betProfiles.length; i++) {
    const item = betProfiles[i];
    chart.push(
      <svg width="160" height="160" xmlns="http://www.w3.org/2000/svg">
        <g>
          <title>{item.type}</title>
          <circle
            r="60" cy="80" cx="80"
            strokeDasharray={2 * Math.PI * 60}
            strokeDashoffset={2 * Math.PI * 60 * (1 - item.percentage)}
            // transform="rotate(90,80,80)"
            transform={`rotate(${endedLast},80,80)`}
            strokeWidth={item.type === "high" ? 40 : 30}
            stroke={strokeColor(item.type)}
            fill="none"
          />
        </g>
      </svg>)
    endedLast += 360 * item.percentage;
  }
  return chart;
}