// Action Types

export const Types = {
  LOGIN: 'auth/LOGIN',
  LOGOUT: 'auth/LOGOUT',
  EDIT_USER: 'auth/EDIT_USER',
  ADD_SUBSCRIPTION: 'auth/ADD_SUBSCRIPTION',
};

// Reducer

const initialState = {
  isLogged: false,
  user: {},
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case Types.LOGIN:
      return { ...state, user: action.payload.user, isLogged: true };
    case Types.LOGOUT:
      return { ...state, user: {}, isLogged: false };
    case Types.EDIT_USER:
      const newStateEditUser = { ...state, user: {...state.user, ...action.payload.user} };
      localStorage.setItem("user", JSON.stringify(newStateEditUser.user));
      return newStateEditUser;
    case Types.ADD_SUBSCRIPTION:
      const newState = { ...state, user: {...state.user, subscriptions: [...state.user.subscriptions, action.payload.newSubscription]} };
      localStorage.setItem("user", JSON.stringify(newState.user));
      return newState;
    default:
      return state;
  }
}

// Action Creators

export function loginSuccess(user) {
  localStorage.setItem("user", JSON.stringify(user));
  window.OneSignal.sendTags({
    "match": user.match,
    "app_news": user.app_news,
    "score": user.score,
    "bet_news": user.bet_news,
  });
  return {
    type: Types.LOGIN,
    payload: {
      user,
    },
  }
}

export function logout() {
  localStorage.removeItem("user");
  return {
    type: Types.LOGOUT,
  }
}

export function editUserSuccess(user) {
  // localStorage.setItem("user", JSON.stringify(user));
  return {
    type: Types.EDIT_USER,
    payload: {
      user,
    },
  }
}

export function addSubscription(newSubscription) {
  return {
    type: Types.ADD_SUBSCRIPTION,
    payload: {
      newSubscription,
    },
  }
}
