import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Routes from './routes';
import { loginSuccess } from './store/ducks/auth';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if(user) {
      dispatch(loginSuccess(user));
    }
  }, [])

  return (
    <div className="App">
      <ToastContainer
      position="top-right"
      autoClose={5000}
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover 
      hideProgressBar={true}/>
      <Routes />
    </div>
  );
}

export default App;
