import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OutlineButton } from '../../components/Button';
import { getTransactions } from '../../providers/userServicesProvider';
import Spinner from '../../components/LoadingSpinner';
import { Td } from './styles';
import './styles.css';

const Transactions = props => {
  const user = useSelector(state => state.auth.user);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(false);

  useEffect(() => {
    if (limit) return;
    if (loading) return;
    if (!user.token) return;
    const getData = async () => {
      setLoading(true);
      const result = await getTransactions(user.id, user.token, page);
      if (result.error) {
        setError("Houve um problema ao carregar as suas transações");
        setLoading(false);
        return;
      }
      setList(state => [...state, ...result.results]);
      if (!result.next) {
        setLimit(true);
      }
      setLoading(false);
    }
    getData();
  }, [user.id, user.token, page, limit])

  const nextPage = () => {
    if (limit) return;
    setPage(page + 1);
  }

  const handleBoleto = (item) => {
    if(item.payment_type === "Boleto") {
      window.open(item.boleto_url, '_blank');
    }
  }

  console.log(list);
  return (
    <main className="transactions-screen">

      <h1>Extrato</h1>

      {loading ? <Spinner size="large" /> :
        <table className="transactions-table">
          <thead>
            <tr>
              <th>Data</th>
              <th>Descrição</th>
              <th>Tipo</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {list.map(item => (
              <tr key={`transaction-${item.id}`}>
                <td>{new Date(item.created_at).toLocaleDateString('pt-br')}</td>
                <td className="description">{item.description}</td>
                {item.payment_type === "Boleto" ? 
                <Td onClick={() => handleBoleto(item)}>
                  {item.payment_type} <br/>
                  ({item.boleto_status})
                </Td> : <td>{item.payment_type}</td>}
                {renderValueInfo(item.value, item.description)}
              </tr>
            ))}
          </tbody>
        </table>}

      {!loading && !limit ? (
        <OutlineButton className="pagination-btn" onClick={nextPage}>Ver mais</OutlineButton>
      ) : null}


    </main>

  )
}

const renderValueInfo = (value, type) => {
  let className = "remove";
  let sign = "-"
  if (type === "Créditos" || type === "Créditos Por Conversão") {
    className = "add";
    sign = "+"
  }
  return (
    <td className={className}>{sign} Z$ {value}</td>
  )
}

export default Transactions;
