import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { OutlineButton, SubmitButton } from '../../components/Button';
import { Alert, Input } from '../../components/Form';
import Checkbox from '../../components/Form/Checkbox';
import TermsModal from '../../components/TermsModal';
import { register } from '../../providers/authProvider';

import './styles.css';

const initialPayload = {
  name: "",
  email: "",
  password: "",
}

const Register = props => {

  const [payload, setPayload] = useState(initialPayload);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [modal, setModal] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const isLogged = useSelector((state) => state.auth.isLogged);
  const dispatch = useDispatch();

  const onChangePayload = e => {
    setPayload({...payload, [e.target.name]: e.target.value});
  }

  const isValid = () => {
    if(Object.values(payload).some(value => !value)) {
      setError("Preencha todos os campos para concluir seu cadastro");
      return false;
    }

    const _full_name = payload.name.split(" ");
    if(_full_name.length < 2) {
      setError("Informe um Sobrenome");
      return false;
    }

    if(_full_name.some(value => value.length < 2)) {
      setError("Informe um Sobrenome válido");
      return false;
    }
    
    if(payload.password !== confirmPassword) {
      setError("Verifique se a senha foi digitada corretamente");
      return false;
    }

    if(!termsChecked) {
      setError("É necessário concordar com os termos e condições para realizar o cadastro");
      return false;
    }

    return true;
  }

  const send = async e => {
    e.preventDefault();
    if(!isValid()) {
      return;
    }
    setLoading(true);
    setError("")


    const result = await dispatch(register(payload));
    setLoading(false);
    if(result.error) {
      setError("Ocorreu um erro com seu cadastro. Tente novamente!");
      return;
    }
  }

  if(isLogged) {
    history.push("/app/usuario");
    return null;
  }

  const openModal = (event) => {
    event.preventDefault();
    setModal(true);
  }

  return (
    <div className="register-screen">
      <main>

        <h1>Cadastro</h1>

        <form>

          <Input name="name" value={payload.name} placeholder="Nome" onChange={onChangePayload} />
          <Input type="email" name="email" value={payload.email} placeholder="Email" onChange={onChangePayload} />
          <Input type="password" name="password" value={payload.password} placeholder="Senha" onChange={onChangePayload} />
          <Input type="password" name="confirm-password" value={confirmPassword} placeholder="Confirmar senha" onChange={e => setConfirmPassword(e.target.value)} />

          <Checkbox checked={termsChecked} onChange={() => setTermsChecked(!termsChecked)}>
            Concordo com os Termos e Condições do Bolão Zé do Bet
          </Checkbox>

          <span onClick={openModal} className="terms-link">
            <OutlineButton>Termos e condições</OutlineButton>
          </span>

          <Alert show={error}>{error}</Alert>

          <SubmitButton onClick={send}>
            {loading ? "Carregando..." : "Cadastrar"}
          </SubmitButton>

        </form>

      </main>
      <TermsModal toggle={() => setModal(!modal)} isOpen={modal} />
    </div>
  )
}

export default Register;
