import React from "react";

import Modal from "../Modal/Modal";
import { ChoiceModalContent } from './style';
import { SubmitButton } from "../Button";


const ChoicePaymentModal = (props) => {

  return (
    <Modal toggle={props.toggle} isOpen={props.isOpen}>
      <ChoiceModalContent>
        <h3>Escolha a forma de pagamento.</h3>
        <SubmitButton style={{width: 250}} onClick={props.openCreditCard}>Cartão de Crédito</SubmitButton>
        <br/>
        <SubmitButton style={{width: 250}} onClick={props.openBoleto}>Boleto</SubmitButton>
      </ChoiceModalContent>
    </Modal>
  );
};

export default ChoicePaymentModal;
