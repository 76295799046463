import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { boletoPay } from '../../providers/userServicesProvider';
import showToast from '../../utils/Toast';
import BoletoAddInfor from '../BoletoAddInfor';

import { UnderlinedInput } from '../Form';
import Modal from "../Modal/Modal";
import { BoletoModalContent, SubmitButton } from './style';


const BoletoModal = (props) => {
  const user = useSelector(state => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    user: user.id,
    transaction_type: 'Créditos',
    total_amount: 0,
    amount_credit: 0,
    description: 'Pagamento Boleto'
  });

  const onChangePayload = (name, value) => {
    setPayload({...payload, [name]: value});
  }

  const onSubmit = async () => {
    setLoading(true);
    const response = await boletoPay(user.token, {...payload, total_amount: payload.amount_credit});
    setLoading(false);
    if(response) {
      const { payment_method } = response;
      window.open(payment_method.url, '_blank');
      props.toggle();
    } else {
      showToast('Houve um problema com o pagamento com boleto');
    }
  }

  return (
    <Modal toggle={props.toggle} isOpen={props.isOpen}>
      <BoletoModalContent>
        {(user.city && user.neighborhood && user.postal_code && user.state && user.street) ? <>
        <h2 className="title">Adicionar Créditos</h2>
        <form>
          <label>
            Quantidade de créditos
            <UnderlinedInput
              name="amount_credit"
              type="number"
              value={payload.amount_credit}
              onChange={({target}) => onChangePayload('amount_credit', target.value)}
            />
          </label>
        </form>
        <SubmitButton disabled={loading} type="button" onClick={onSubmit}>
          {loading ? `Carregando` : `Confirmar`}
        </SubmitButton>
        </> : <BoletoAddInfor />}
      </BoletoModalContent>
    </Modal>
  )
}

export default BoletoModal;