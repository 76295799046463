import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { editUser } from '../../providers/authProvider';
import { FormatUnderlinedInput, UnderlinedInput } from '../Form';
import { SubmitButton } from '../Button';
import { validCpf } from '../../utils/validations';
import { FormBoleto } from './styles';
import showToast, { SUCCESS } from '../../utils/Toast';
import StateJson from '../../utils/states.json';
import { getAddressCEP } from '../../providers/userServicesProvider';
import NumberFormat from 'react-number-format';

const BoletoAddInfor = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  
  const [payload, setPayload] = useState({
    taxpayer: user.taxpayer ? user.taxpayer : '',
    city: user.city ? user.city : '',
    state: user.state ? user.state : '',
    neighborhood: user.neighborhood ? user.neighborhood : '',
    postal_code: user.postal_code ? user.postal_code : '',
    street: user.street ? user.street : '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(payload.postal_code.length === 8) {
      loadCEP();
    }
  }, [payload.postal_code]);

  const loadCEP = async () => {
    setLoading(true);
    const result = await getAddressCEP(user.token, payload.postal_code);
    setLoading(false);
    if(result.error) {
      const { message } = result.message.data;
      showToast(message);
    } else {
      const _state_name = StateJson.estados.find(item => item.sigla == result.uf)?.nome;
      setPayload({
        ...payload,
        neighborhood: result.bairro,
        city: result.cidade,
        state: _state_name ? _state_name : '',
        street: result.logradouro
      });
    }
  }
  
  const handleChange = (name, value) => {
    if(name === 'postal_code') {
      if(value.length < 9) {
        setPayload({...payload, [name]: value})
      }
    } else {
      setPayload({ ...payload, [name]: value });
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validField()) {
      setLoading(true);
      const result = await dispatch(editUser(user.id, payload, user.token));
      setLoading(false);
      if (!result.error) {
        showToast('Dados Salvo', SUCCESS);
      } else {
        showToast('Verifique os dados corretamente');
      }
    }
  }

  const validField = () => {
    if (!validCpf(payload.taxpayer)) {
      showToast('Informe um CPF válido');
      return;
    }
    if (!payload.postal_code) {
      showToast('Informe um CEP válido');
      return;
    }
    if (!payload.city) {
      showToast('Informe a Cidade');
      return;
    }
    if (!payload.state) {
      showToast('Informe o Estado');
      return;
    }
    if (!payload.neighborhood) {
      showToast('Informe o Bairro');
      return;
    }
    if (!payload.street) {
      showToast('Informe a Rua');
      return;
    }

    return true;
  }

  return (<>
    <h2 className="title">Adicionar Informações</h2>
    <FormBoleto>
      <label>
        CPF
        <UnderlinedInput
          disabled={user.taxpayer}
          type="number" value={payload.taxpayer} name="taxpayer"
          onChange={({ target }) => handleChange('taxpayer', target.value)} />
      </label>
      <label>
        CEP
        <FormatUnderlinedInput
          value={payload.postal_code} name="postalcode" format="#####-###"
          onValueChange={({ value }) => handleChange('postal_code', value)} />
      </label>
      <label>
        Estado
        <UnderlinedInput
          value={payload.state} list="states" name="state" id="state"
          onChange={({target}) => handleChange('state', target.value)} />
        <datalist id="states">
          {StateJson.estados.map((item, index) => (
            <option value={item.nome}>{item.nome}</option>
          ))}
        </datalist>
      </label>
      <label>
        Cidade
        <UnderlinedInput
          value={payload.city} list="cities" name="city"
          id="city" onChange={({target}) => handleChange('city', target.value)} />
        <datalist id="cities">
          {StateJson.estados.find((item) => item.nome === payload.state)?.cidades.map((item, index) => (
            <option value={item}>{item}</option>
          ))}
        </datalist>
      </label>
      <label>
        Bairro
        <UnderlinedInput
          value={payload.neighborhood} name="neighborhood"
          onChange={({ target }) => handleChange('neighborhood', target.value)} />
      </label>
      <label>
        Rua
        <UnderlinedInput
          value={payload.street} name="street"
          onChange={({ target }) => handleChange('street', target.value)} />
      </label>
    </FormBoleto>
    <SubmitButton disabled={loading} type="button" onClick={handleSubmit}>
      {loading ? `Carregando` : `Confirmar`}
    </SubmitButton>
  </>
  );
}

export default BoletoAddInfor;