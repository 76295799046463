import React from 'react';
import { NavLink } from 'react-router-dom';

import './style.css';
import logo from "../../../assets/landpage/escudo.png";

const Header = props => {

  return (
    <header className="landing-header">
      <img src={logo} className="logo" alt="Logo Zé do Bet" />
      <span className="header-title">O Melhor Bolão de Futebol do Brasil Com Prêmios em Dinheiro</span>

      <nav>
        <NavLink className="btn-link btn-outline" to="/login">Entrar</NavLink>
        <NavLink className="btn-link btn-primary" to="/cadastro">Cadastrar</NavLink>
      </nav>

    </header>
  )
}

export default Header;
