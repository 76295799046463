import React from 'react';
import Checkbox from '../../components/Form/Checkbox';

import './styles.css';

const Terms = props => {
  return (
    <div className="terms-screen">
      <main>

        <div className="terms-header">
          <h1>Termos e condições</h1>
          <h2 className="logo-title">Bolão Zé do Bet</h2>
        </div>

        <div className="terms-content">
          <p>
          O presente Termos e Condições Gerais de Uso (“T&C”), tem por objetivo apresentar as regras e procedimentos para a filiação ao Zé do Bet (“Zé do Bet”).
          </p>
          <p>
          É de suma importância que você leia o presente T&C na íntegra, pois ao marcar a opção "Li e estou de acordo com as cláusulas e regras do Termos e Condições Gerais de Uso” constante do formulário de cadastro, você estará declarando ter lido e aceito todas as cláusulas e condições do presente T&C, às quais estará vinculado.
          </p>
          <h2>DEFINIÇÕES</h2>
          <p>
          1.1. As seguintes palavras e/ou expressões, quando iniciadas por letra maiúscula no presente T&C, terão os significados aqui atribuídos, sem prejuízo de outras palavras e/ou expressões definidas no decorrer do presente T&C:
          </p>
          <p>
          “Código Penal” significa o Decreto-Lei nº 2.848 de 7 de dezembro de 1940.
          </p>
          <p>
          “Conta do Jogador” significa a conta online do Jogador, composta de nickname e ID de usuário gerida e administrada pelo Zé do Bet, de uso restrito do Jogador, criada após a efetivação do cadastro de filiação do Jogador no Zé do Bet, onde o Jogador terá acesso a informações como: saldo de Créditos; extrato de resultados dos Torneios, dentre outras informações, bem como poderá aderir a Torneios, resgatar valores para a conta corrente de sua titularidade, dentre outras atividades;
          </p>
          <p>
          “Crédito(s)” significa os créditos eletrônicos, os quais são adquiridos pelos Jogadores no Zé do Bet, mediante o pagamento de determinada quantia em moeda corrente nacional e creditamento na Conta do Jogador, de modo a possibilitar que estes participem dos Torneios, não sendo os mesmos objeto de venda, permuta para outros Jogadores ou utilização fora do ambiente do Zé do Bet;
          </p>
          <p>
          “Jogador(es)” significa a pessoa física, possuidora de plena capacidade civil, devidamente filiada ao Zé do Bet, que deseja participar dos Torneios no âmbito do Zé do Bet e que desde já concorda com todas as disposições do presente T&C;
          </p>
          <p>
          “Prêmio(s)” significa o repasse de determinados Créditos devidos ao Jogador vencedor de um Torneio, que é composto por todos os Créditos resultantes dos palpites válidos feitos por todos os Jogadores que participaram do referido Torneio, descontada a Comissão e ainda observado o Regulamento de cada Torneio;
          </p>
          <p>
          “Regulamento(s)” significa as regras e procedimentos aplicáveis a cada Torneio individualmente, e que deverão ser previamente aceitos pelos Jogadores anteriormente a sua adesão ao Torneio.
          </p>
          <p>
          “Zé do Bet” significa a pessoa jurídica, devidamente constituída, com atividade de intermediação de negócios e organização de Torneios com exclusivo propósito de recreação e entretenimento;
          </p>
          <p>
          “Torneio(s)” significa um evento organizado e gerenciado pelo Zé do Bet, no qual Jogadores aderem previamente e apresentam os seus palpites para resultados de partidas ou grupos de partidas de futebol oficiais definidas pelo Zé do Bet, tendo um prazo definido para o seu início e seu término, com o repasse do Prêmio ao(s) vencedor(es), nos termos do seu Regulamento. Para fins do presente T&C, os eventos denominados “jogo do sobrevivente” serão considerados como Torneios.
          </p>
          <h2>CADASTRO AO ZÉ DO BET E UTILIZAÇÃO DA PLATAFORMA</h2>
          <p>
          2.1. Para o cadastramento e filiação ao Zé do Bet, o Jogador deverá criar uma Conta do Jogador através do website (http://www.zedobet.com.br), bem como informar os seguintes dados pessoais, além de outros que possam ser futuramente exigidos (“Dados Pessoais”):
          </p>
          <p>
          (i) Nome completo;
          </p>
          <p>
          (ii) CPF;
          </p>
          <p>
          (iii) Endereço residencial completo;
          </p>
          <p>
          (iv) Dados bancários (Banco, agência e nº de conta);
          </p>
          <p>
          (v) E-mail de contato.
          </p>
          <p>
          2.2. Os Dados Pessoais fornecidos para o cadastramento, bem como suas atualizações são de inteira responsabilidade do Jogador, que desde já isenta o Zé do Bet em caso de informações incompletas e/ou falsas. Todo o tratamento dos Dados Pessoais será feito de forma a proteger e respeitar a privacidade do Jogador, com base na lei e melhores práticas de mercado nos termos da Cláusula 10.4.1.
          </p>
          <p>
          2.2.1. O fornecimento de informações falsas e/ou a utilização indevida de dados de terceiros em nome próprio constitui crime tipificado pelo Código Penal. Em qualquer desses casos, o Zé do Bet poderá suspender ou cancelar definitivamente o acesso do Jogador à Conta do Jogador, sem prejuízo das demais medidas que lhe sejam asseguradas pela legislação em vigor.
          </p>
          <p>
          2.3. Após realizada a filiação do Jogador ao Zé do Bet, o Jogador poderá visualizar no painel disponibilizado pelo Zé do Bet a relação de Torneios dos quais ele poderá participar, bem como a quantidade mínima de Créditos necessária para cada Torneio, sendo certo que, em hipótese nenhuma o Jogador poderá participar de um mesmo Torneio com 2 (duas) ou mais contas vinculadas ao seu CPF ou vinculadas ao CPF de outras pessoas (terceiros) e não operar contas de outros Jogadores.
          </p>
          <p>
          2.3.1. O Jogador perderá imediatamente o acesso aos Torneios caso o Jogador seja excluído ou, por qualquer outra razão, deixe de estar vinculado ao Zé do Bet.
          </p>
          <p>
          2.3.2. Sem prejuízo de outras medidas cabíveis, o Zé do Bet se reserva o direito de suspender e/ou excluir a Conta do Jogador, retirar o Jogador de Torneios iniciados ou a iniciar, aplicar penalidades em virtude da apuração de práticas irregulares do Jogador em Torneios, inclusive Torneios já encerrados, sempre a exclusivo critério do Zé do Bet, se:
          </p>
          <p>
          (i) Houver suspeita de que o Jogador esteja envolvido com lavagem de dinheiro, atividades ilegais ou fraudulentas ao usar a Plataforma do Zé do Bet;
          </p>
          <p>
          (ii) Houver suspeita de que os recursos utilizados para aquisição de Créditos tenha origem ilícita ou obscura;
          </p>
          <p>
          (iii) Houver evidência de que o Jogador está envolvido em qualquer tipo de interferência ilegal no resultado do Torneio, visando evadir das regras do Zé do Bet ou deste T&C;
          </p>
          <p>
          (iv) Houver evidência de que o Jogador utilizou qualquer dispositivo, robô, spider, software, rotina ou outro método de natureza semelhante para interferir ou tentar interferir no funcionamento normal e adequado dos serviços do Zé do Bet;
          </p>
          <p>
          (v) Houver evidência de que o Jogador está atuando ou tentando atuar, sozinho ou com outros Jogadores, com o objetivo de lesar o Zé do Bet e/ou outros Jogadores;
          </p>
          <p>(vi) Houver evidência de descumprimento de regras expressas neste T&C; </p>
          <p>(vii) Houver evidência de que o Jogador está agindo de forma prejudicial à condução dos negócios do Zé do Bet.</p>
          <p>
          2.4. O Jogador poderá obter Créditos para operar no Zé do Bet mediante transferência de recursos (nas modalidadades TED/DOC/Pix) para conta corrente de titularidade do Zé do Bet, mediante utilização de cartão de crédito de titularidade do Jogador, ou ainda por crédito pré-pago (“Reward”). Não é permitida a transferência de Créditos entre Jogadores. O Zé do Bet poderá, a seu exclusivo critério, fornecer outras formas de pagamento aos Jogadores.
          </p>
          <p>
          2.4.1. A transferência de valores do Jogador para o Zé do Bet deverá ser realizada exclusivamente através de conta corrente de titularidade do Jogador, não sendo permitidas transferências através de contas de terceiros. Qualquer valor eventualmente recebido pelo Zé do Bet originário de conta corrente que não a do próprio Jogador será imediatamente estornado, sendo os Créditos excluídos da Conta do Jogador. O Zé do Bet não se responsabiliza pela não participação do Jogador em determinados Torneios em razão da não observância desta cláusula pelo Jogador.
          </p>
          <p>
          2.5. Os Créditos serão disponibilizadas pelo Zé do Bet na Conta do Jogador no prazo de até 5 (cinco) dias úteis após a confirmação de recebimento e conferencia dos valores pelo Zé do Bet. O Zé do Bet não se responsabiliza pela não participação do Jogador em determinados Torneios em razão da não disponibilização de Créditos na Conta do Jogador suficientes para sua participação em um Torneio e com a antecedência necessária prevista na Cláusula 2.7.
          </p>
          <p>
          2.6. O Zé do Bet poderá, a seu exclusivo critério e por mera liberalidade, disponibilizar uma quantidade superior de Créditos ao Jogador em relação à quantidade de Créditos correspondentes ao pagamento por ele efetuado, a título de promoção, visando incentivar a participação do Jogador nos Torneios (“Promoção”).
          </p>
          <p>
          2.7. O Jogador poderá participar de um Torneio se o mesmo ainda não tiver se iniciado. Um Torneio é considerado iniciado 1 (uma) hora antes do fechamento dos palpites, horário esse oficial definido pelo Zé do Bet, conforme definido pelo Regulamento do Torneio, sendo que a partir deste momento não será mais aceita qualquer adesão ao Torneio. Essa definição poderá ser alterada sem prévio aviso. O Jogador concorda que, assim que o Torneio tiver iniciado, não será possível desistir da adesão ao Torneio ou receber qualquer tipo de ressarcimento ou devolução de Créditos, nem mesmo alterar seus palpites.
          </p>
          <p>
          2.7.1. Os palpites dos Jogadores que forem recebidos corretamente pelos servidores do Zé do Bet com antecedência mínima de 30 (trinta) minutos antes do horário previsto para o fechamento dos palpites daquela rodada. Caso o jogador não faça seus palpites até o horário limite, o sistema marcará automaticamente todos os palpites com o resultado 0x0 e estes valerão para o os jogos em questão. Os palpites submetidos não serão passíveis de alteração após o prazo estabelecido nesta cláusula. Caso ocorra falha na comunicação e o palpite não seja recebido pelos servidores do Zé do Bet até o prazo previsto nesta cláusula, o palpite não será válido e os Créditos referentes à adesão ao Torneio não retornarão ao saldo da Conta do Jogador. O Jogador será informado sobre o resultado do seu palpite após o resultado do Torneio ser definido.
          </p>
          <p>
          2.7.2. Caso uma partida não seja iniciada na data agendada, ou começar e posteriormente for adiada e/ou abandonada sem ser completada (retomada) até o fim do próximo dia, todos os palpites da partida em questão serão anulados e desconsiderados na pontuação do Torneio. Os Jogadores concordam em isentar completamente o Zé do Bet de qualquer responsabilidade pela anulação dos palpites em decorrência destes fatos.
          </p>
          <p>2.7.3. Caso uma partida seja anulada posteriormente à definição do seu resultado por qualquer terceiro, o Torneio não será retroativamente anulado, sendo válidos todos os palpites efetuados para a referida partida, bem como confirmados os Créditos e/ou Prêmios distribuídos aos Jogadores. Os Jogadores concordam em isentar completamente o Zé do Bet de qualquer responsabilidade pela anulação de partidas não realizadas pelo Zé do Bet.</p>
          <p>2.8. O Jogador poderá resgatar seus Créditos e/ou Prêmios obtidos nos Torneios a qualquer momento, acessando a Conta do Jogador a ele vinculada. Sendo realizada a solicitação de resgate, o Zé do Bet irá efetuar a transferência dos valores referentes aos Créditos/Prêmios resgatados no prazo de até 5 (cinco) dias úteis subsequentes à solicitação, para conta corrente de titularidade do Jogador cadastrada junto ao Zé do Bet.</p>
          <p>2.9 O jogador terá a sua disposição o jogo curinga o qual valerá a pontuação dobrada para quele jogo. Caso o jogador não escolha o jogo curinga na rodada, o sistema escolherá por padrão o primeiro jogo da rodada como jogo curinga. O jogo curinga não poderá ser alterado após o início da rodada.</p>
          <h2>PAGAMENTO PELA UTILIZAÇÃO DA PLATAFORMA</h2>
          <p>3.1. Pelos serviços prestados ao Jogador no âmbito do Zé do Bet, este fará jus a um percentual a ser descontado da quantidade total de Créditos utilizada por cada Jogador em cada Torneio, na proporção de 10% a 30% do valor total de Créditos utilizado por cada Jogador em cada Torneio, nos termos do Regulamento de cada Torneio (“Comissão”).</p>
          <p>3.2. O Zé do Bet atuará como depositário dos valores pagos pelos Jogadores convertidos em Créditos e utilizados por cada Jogador em cada Torneio, fazendo jus unicamente ao valor de sua Comissão, de forma que, em nenhuma hipótese, a totalidade dos Créditos utilizados por cada Jogador em cada Torneio poderá ser considerada como receita do Zé do Bet.</p>
          <p>3.3. Todos os tributos que, segundo a legislação aplicável, incidam sobre a Comissão, serão suportados pelo Zé do Bet, de acordo com o que estabelecer a legislação tributária. Adicionalmente, caberá a cada Jogador cumprir com suas obrigações tributárias, decorrentes de Prêmios havidos, de acordo com a legislação aplicável.</p>
          <h2>OBRIGAÇÕES DAS PARTES</h2>
          <p>4.1. São obrigações do Jogador:</p>
          <p>(i) não agir de qualquer maneira em desacordo com a boa ordem legal, administrativa e operacional;</p>
          <p>(ii) fornecer e manter sempre atualizados os Dados Pessoais ao Zé do Bet para a participação nos Torneios, responsabilizando-se pela veracidade deles;</p>
          <p>(iii) manter a guarda e sigilo em relação ao login/senha fornecidos pelo Zé do Bet para acesso à Conta do Jogador;</p>
          <p>(iv) não permitir que terceiros acessem a Conta do Jogador vinculada a seu CPF;</p>
          <p>(v) agir com responsabilidade e de boa-fé durante a participação nos Torneios;</p>
          <p>(vi) tratar os outros Jogadores de forma respeitosa;</p>
          <p>(vii) respeitar a privacidade dos outros Jogadores;</p>
          <p>(viii) não agir de qualquer maneira que, a critério do Zé do Bet, cause ou possa causar prejuízo à reputação do Zé do Bet.</p>
          <h2>4.2. São obrigações do Zé do Bet:</h2>
          <p>(i) realizar a divulgação aos Jogadores de todas as informações necessárias para a participação nos Torneios;</p>
          <p>(ii) fornecer os Créditos aos Jogadores, mediante solicitação destes, permitindo assim que os Jogadores participem dos Torneios;</p>
          <p>(iii) administrar os valores transferidos pelo Jogador para a Conta do Jogador;</p>
          <p>(iv) realizar a retenção da Comissão, recolher os tributos sobre os valores que lhe são devidos;</p>
          <p>(v) efetuar o repasse do Prêmio ao Jogador, bem como de qualquer outro valor que este tenha direito estando ou não este valor vinculado a sua Conta do Jogador, no prazo de até 2 (dois) dias úteis, após a solicitação de resgate pelo Jogador;</p>
          <p>(vi) garantir que todos os Jogadores realizem o aceite do T&C.</p>
          <h2>DECLARAÇÕES VINCULANTES</h2>
          <p>5.1. O Jogador, ao concordar com o T&C e utilizar os serviços do Zé do Bet declara que:</p>
          <p>(i) é (a) maior de 18 (dezoito) anos de idade ou (b) detentor de qualquer outra idade legal ou possui maioridade, conforme determinado por quaisquer leis que sejam aplicáveis ao Jogador, qual das duas a que seja maior, sendo certo que o Zé do Bet poderá, a qualquer momento, solicitar comprovantes de idade, de forma a assegurar que o acesso à Plataforma não está sendo realizado por menores de idade, bem como terá o direito de suspender ou cancelar, temporária ou permanentemente, a Conta do Jogador, caso o comprovante de idade solicitado não seja enviado no prazo determinado pelo Zé do Bet;</p>
          <p>(ii) possui total capacidade para contratar os serviços do Zé do Bet e não está restrito por qualquer forma de limitação de capacidade legal;</p>
          <p>(iii) participa dos Torneios exclusivamente para propósitos pessoais e não profissionais; e participa apenas para fins de recreação e entretenimento;</p>
          <p>(iv) participa dos Torneios e coloca palpites por seu interesse próprio, e não por interesse de qualquer outra pessoa ou grupo;</p>
          <p>(v) Todas as informações que o Jogador provê ao Zé do Bet durante o termo de validação desse acordo são verdadeiras, completas e corretas, e que deverá notificar imediatamente em caso de qualquer alteração dessas informações;</p>
          <p>(vi) é exclusivamente responsável por declarar e prestar contas de quaisquer tributos por ele devido, de acordo com as leis aplicáveis, incidentes sobre qualquer Prêmio por ele recebido;</p>
          <p>(vii) ao usar os serviços do Zé do Bet, o Jogador tem total ciência dos riscos envolvidos pelos palpites em Torneios, de forma que isso poderá afetar os Créditos disponíveis na Conta do Jogador, sendo este o único responsável pelos seus palpites, isentando totalmente o Zé do Bet nesse sentido;</p>
          <p>(viii) não utilizará os serviços do Zé do Bet enquanto localizado em qualquer jurisdição que proíba a colocação e/ou aceitação de palpites, sendo certo que não é de responsabilidade do Zé do Bet a verificação da legalidade do acesso à Plataforma nas jurisdições onde o Jogador se encontra, de modo que tal acesso é de única e exclusiva responsabilidade do Jogador na respectiva jurisdição;</p>
          <p>(ix) É permitido na jurisdição em que o Jogador está localizado o uso de serviços de palpites esportivos;</p>
          <p>(x) Os recursos utilizados pelo Jogador deverão ter origem legal e serem de titularidade do Jogador;</p>
          <p>(xi) aceita e concorda que o Zé do Bet se reserva o direito de detectar e prevenir o uso de técnicas proibidas, incluindo, mas não se limitando à detecção de transações fraudulentas, cadastro e autenticação automático, técnicas de captura do jogo ou da tela. Essas medidas poderão incluir, mas não se limitarão a examinar os dispositivos de propriedade dos Jogadores, detecção de geo-localização e máscara de IP, análise de transações e lastro;</p>
          <p>(xii) aceita o direito do Zé do Bet de encerrar e/ou alterar qualquer Torneio, e de recusar e/ou limitar os palpites.</p>
          <h2>SUPORTE AOS JOGADORES</h2>
          <p>6.1. O Zé do Bet disponibiliza atendimento aos Jogadores através de seus Canais de Atendimento ao Cliente, com funcionamento permanente, 24 horas, todos os dias da semana, sendo o atendimento realizado através de chat, via WhatsApp ou e-mail.</p>
          <p>6.2. O Jogador poderá entrar em contato com um dos Canais de Atendimento para: a) sugestões; b) dúvidas; ou c) reclamações. O Zé do Bet se resguarda no direito de responder o Jogador em um prazo mínimo de 48 (quarenta e oito) horas.</p>
          <p>6.3. Caso seja apurada qualquer fraude em um Torneio pelo Zé do Bet, este se reserva o direito de: a) excluir a participação do Jogador envolvido na fraude de Torneios passados e futuros; b) adotar medidas de restituição dos Créditos do Torneio fraudado; e/ou c) suspender ou bloquear o Jogador da Plataforma.</p>
          <h2>CONCESSÃO DE LICENÇA DE USO E PROPRIEDADE INTELECTUAL</h2>
          <p>7.1. De acordo com as condições do presente T&C, quando o Jogador se filiar ao Zé do Bet, ele passa a ter o direito limitado, não-exclusivo, pessoal, intransferível, não licenciável, revogável para se utilizar da Plataforma e participar dos Torneios ali disponibilizados.</p>
          <p>7.2. O Zé do Bet declara que é detentor dos direitos de uso inerentes à Plataforma. O Jogador não deverá (“Uso Não Autorizado”):</p>
          <p>(i) copiar, distribuir, publicar, fazer engenharia reversa, recompilar, desmantelar, modificar ou traduzir a Plataforma, nem fazer qualquer tentativa de acessar o código fonte, para criar trabalhos derivados dele, ou para quaisquer outros fins;</p>
          <p>(ii) vender, atribuir, sublicenciar, transferir, distribuir ou alugar a Plataforma;</p>
          <p>(iii) tornar a Plataformas disponível para terceiros através de rede de computadores ou de outro modo;</p>
          <p>(iv) exportar a Plataforma para qualquer país (quer por meios físicos ou eletrônicos);</p>
          <p>(v) usar a Plataforma de forma proibida pelas leis aplicáveis.</p>
          <p>7.3. O Jogador concorda que será o único responsável por quaisquer danos, custos ou despesas que ocorram devido à/ou em conexão com qualquer uso não-autorizado da Plataforma. Em caso de uso não autorizado, o Jogador deverá comunicar de imediato o Zé do Bet através de um dos Canais de Atendimento, devendo o Jogador fornecer a assistência razoável em quaisquer investigações conduzidas à luz das informações fornecidas.</p>
          <p>7.4. Os termos "Zé do Bet" e “Zé do Bet Palpites Esportivos”, além de quaisquer outras marcas a ele vinculadas, sinais, nomes usados e/ou nomes de domínios, utilizados pelo Zé do Bet (“Marcas") são de propriedade e titularidade do Zé do Bet, protegidos por direitos autorais (copyright) e/ou propriedade intelectual ou outros direitos.</p>
          <p>7.5. O Jogador não poderá prejudicar ou potencialmente prejudicar os direitos acima descritos, incluindo os direitos de propriedade intelectual, detidos pelo Zé do Bet quanto a Plataforma e Marcas, assim como não fará nada que prejudique a imagem ou reputação do Zé do Bet.</p>
          <p>7.6. O Jogador declara e garante ter conhecimento de que quaisquer nomes ou imagens por ele utilizadas na Plataforma não devem infringir a propriedade intelectual, privacidade ou outros direitos de terceiros.</p>
          <h2>DIVULGAÇÃO DE PUBLICIDADE</h2>
          <p>8.1. No momento da realização do cadastro de filiação do Jogador junto ao Zé do Bet, este poderá dar o seu consentimento para o recebimento de material publicitário enviado pelo Zé do Bet (ex. informações e novidades sobre os Torneios), podendo tal material ser enviado via e-mail, marketing, aplicativos de mensagens instantânea, SMS ou qualquer outro canal de publicidade. O envio de material publicitário somente ocorrerá com o consentimento do Jogador em relação aos canais de publicidade especificamente aceitos por este e poderá ser desativado a qualquer momento, solicitando o cancelamento do cadastro de seu e-mail na listagem de remetentes das publicidades.</p>
          <p>8.2. Caso o Jogador tenha dado seu consentimento para o recebimento do material publicitário encaminhado pelo Zé do Bet e posteriormente deseje cancelar tal consentimento, poderá fazê-lo das seguintes formas:</p>
          <p>(i) em todos os e-mails promocionais estará disponível para o Jogador um link para cancelamento com a seguinte frase: “Caso não queira mais receber nossos e-mails, clique aqui”. Basta clicar no link para o cancelamento do recebimento do material publicitário;</p>
          <p>(ii) o cancelamento também poderá ser realizado através de contato com o Canal de Atendimento do Zé do Bet.</p>
          <h2>DEVER DE INDENIZAR</h2>
          <p>9.1. O Jogador concorda desde já em indenizar na totalidade, defender e isentar o Zé do Bet, seus sócios, diretores e funcionários de todas as reclamações, exigências, responsabilidades, danos, perdas, custos e despesas, incluindo taxas legais e quaisquer outros encargos, independentemente da forma como tenham sido gerados, e que possam surgir como resultado de:</p>
          <p>(i) sua violação ou suposta violação desse T&C, no todo ou em parte;</p>
          <p>(ii) em conexão com os Torneios, qualquer violação ou infração ou suposta violação ou infração de qualquer lei ou direitos de terceiros;</p>
          <p>(iii) qualquer comportamento fraudulento e/ou supostamente fraudulento com o qual o Jogador tenha se envolvido;</p>
          <p>(iv) utilização de sua Conta do Jogador por terceiros, com ou sem a sua autorização.</p>
          <p>9.2. Sob nenhuma circunstância, o Zé do Bet será responsável por quaisquer danos, diretos, indiretos ou consequentes (incluindo danos por perda de lucros de negócios, interrupção de negócios, perda de informações comerciais ou qualquer outra perda pecuniária) decorrentes de ou em conexão com este T&C, mesmo se o Zé do Bet tivesse conhecimento prévio da possibilidade de tais danos ou se tais perdas fossem razoavelmente previsíveis.</p>
          <h2>LIMITES DE RESPONSABILIDADE</h2>
          <p>10.1. Sob nenhuma circunstância, incluindo o caso de negligência, o Zé do Bet deve ser responsabilizado por quaisquer danos especiais, incidentais, diretos, indiretos ou consequentes, incluindo, mas não se limitando a danos por perdas de lucros, interrupção do negócio, perda de informação do negócio, ou qualquer outra perda pecuniária, que decorram do uso da Plataforma pelo Jogador.</p>
          <p>10.2. As estatísticas, placares ao vivo ou textos editoriais publicados no Zé do Bet são considerados informações adicionais e o Zé do Bet não reconhece ou aceita qualquer responsabilidade caso essas informações estejam incorretas.</p>
          <h2>DISPOSIÇÕES GERAIS</h2>
          <p>11.1. Este T&C é celebrado pelo Jogador em caráter irrevogável e irretratável. O Jogador, ao concordar com o T&C, também concorda com as regras do Zé do Bet e a Política de Privacidade que, para fins de referência, são parte integrante deste T&C. Em caso de qualquer inconsistência, este T&C irá prevalecer.</p>
          <p>11.2. Caso qualquer termo ou disposição deste T&C seja ilegal ou inexequível em decorrência de qualquer lei ou política pública, todos os outros termos e disposições deste instrumento continuarão aplicáveis e em vigor, desde que o conteúdo econômico e jurídico dos direitos e das obrigações deste instrumento não tenham sido afetados adversamente de forma relevante para qualquer uma das partes.</p>
          <p>11.3. O Zé do Bet não será responsabilizado por práticas de privacidade e conteúdo de terceiros. Caso o Jogador venha a acessar um anúncio de terceiros através de publicidade encaminhada pelo Zé do Bet, deverá estar ciente de que quaisquer dados pessoais que vier a fornecer não estarão abrangidos pelo presente T&C.</p>
          <p>11.4. O Jogador, ao se cadastrar no Zé do Bet e utilizar seus serviços, declara ter ciência e concordar que o Zé do Bet poderá coletar e manter informações pessoais do Jogador, para permitir o acesso e a participação nos Torneios.</p>
          <p>11.4.1. O Zé do Bet se obriga a tratar as informações pessoais dos Jogadores, de forma a proteger e respeitar a sua privacidade, de acordo com a Lei Geral de Proteção de Dados (“LGPD”) e melhores práticas de mercado. As informações pessoais dos Jogadores serão mantidas por prazo indeterminado, exceto se o Zé do Bet for legalmente obrigado a destruí-las ou, a seu exclusivo critério, houver expirado o dever de retenção dos dados.</p>
          <p>11.5. O Zé do Bet somente irá compartilhar os dados e informações dos Jogadores se necessário para o cumprimento de uma obrigação legal nos termos da legislação aplicável, ou para responder a um processo legal válido.</p>
          <p>11.5.1. Também poderá ocorrer o compartilhamento dos dados dos Jogador em situações de interesse legítimo, em matéria de segurança nacional, cumprimento da lei, investigação criminal, proteção da segurança de qualquer pessoa, ou para impedir a morte ou danos físicos iminentes, desde que consideremos, de forma fundamentada, que tal interesse não prevalece sobre os interesses ou direitos e liberdades fundamentais do Jogador.</p>
          <p>11.6. O presente T&C poderá ser alterado/atualizado pelo Zé do Bet visando sempre garantir uma maior segurança dos Dados Pessoais dos Jogadores. Caso sejam realizadas alterações relevantes que ensejem novas autorizações, o Zé do Bet publicará uma nova versão do T&C que estará disponível para aceite quando o Jogador acessar sua Conta do Jogador. A continuidade do uso dos serviços do Zé do Bet após qualquer alteração/atualização do T&C será considerada como a aceitação e concordância do Jogador em se submeter a tais alterações/atualizações.</p>
          <p>11.7. O Zé do Bet é operado por TG7 Tecnologia Eireli, a qual detém todos os direitos referentes ao uso da plataforma.</p>
          <p>11.8. Este T&C é regido e deve ser interpretado de acordo com as leis da República Federativa do Brasil.</p>
          <p>11.9. Fica eleito o Foro da Comarca de Goiânia, Estado de Goiás, como o competente para dirimir quaisquer questões porventura oriundas do presente T&C, com expressa renúncia a qualquer outro, por mais privilegiado que seja.</p>
        </div>

      </main>
      
    </div>
  )
}

export default Terms;
