import styled from "styled-components";

export const CheckoutContent = styled.div`
  border-radius: 7px;
  padding: 1.5rem;
  width: 320px;
  background-color: var(--bg-secondary);

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    margin-bottom: 1rem;
  }

  button {
    font-size: 0.875rem;
    margin: auto;
    display: block;
    width: 280px;
  }
  form {
    label {
      margin-bottom: 1rem;
      font-size: 1rem;
    }
  }

  .card-info {
    margin: 1rem 0;
    display: grid;
    align-items: end;
    grid-column-gap: 1rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-areas:
      "number number"
      "validity code"
      "name name"
      "taxpayer taxpayer";
  }
  #card-number {
    grid-area: number;
  }
  #validity {
    grid-area: validity;
  }
  #security-code {
    grid-area: code;
  }
  #holder-name {
    grid-area: name;
  }
  #taxpayer {
    grid-area: taxpayer;
  }

  .price {
    margin: 1rem 0;
    span {
      background-color: var(--action-tertiary);
      padding: 0.5rem;
      border-radius: 5px;
      font-weight: 700;
    }
  }

  .feedback-msg {
    margin: 1rem 0;
  }

  .submit-btn {
    max-width: 100%;
  }
`;

export const OptionContent = styled.div`
  border-radius: 7px;
  padding: 1.5rem;
  width: 320px;

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  button {
    font-size: 0.875rem;
    margin: auto;
    display: block;
    width: 280px;
    margin-bottom: 16px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0;
    grid-column-gap: 1rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-areas:
      "number number"
      "validity code"
      "name name"
      "taxpayer taxpayer";
  }
  #card-number {
    grid-area: number;
  }
  #validity {
    grid-area: validity;
  }
  #security-code {
    grid-area: code;
  }
  #holder-name {
    grid-area: name;
  }
  #taxpayer {
    grid-area: taxpayer;
  }
`;