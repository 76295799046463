import axios from "axios";

export let baseURL = '';

const currentUrl = window.location.href;

// Verifica Qual base está ativo
if (currentUrl.includes("zedobet.com")) {
  baseURL = "https://api.zedobet.com/api/v1";
} else if (currentUrl.includes("devaribox.co")) {
  baseURL = "https://zedobet-api.devaribox.co/api/v1";
} else {
  baseURL = "https://zedobet-api.devaribox.co/api/v1";
}

const config = axios.create({
  baseURL: baseURL
});

export default config;
