import React, { useEffect, useState } from "react";

import { ConvertModalContent } from './convertModalStyles';
import Modal from "../Modal/Modal";
import { Alert, AlertSuccess, UnderlinedInput } from "../Form/index";
import { convertWithdraw } from '../../providers/userServicesProvider';
import { SubmitButton } from "../Button";
import { useSelector } from "react-redux";
import showToast, { SUCCESS } from "../../utils/Toast";


const ConvertModal = (props) => {

  const user = useSelector(state => state.auth.user);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    setError('');
    setSuccess('');
    setValue(0);
  }, [props.isOpen]);

  const send = async () => {
    if(!value) {
      setError("Informe um valor válido");
      return ;
    }
    setLoading(true);
    const result = await convertWithdraw({
      user: user.id,
      value: value
    }, user.token);
    setLoading(false);
    if(result.error) {
      if(result.message.status === 406) {
        setError("Você não possui saldo de saque disponível");
      } else {
        setError("Não foi possível fazer esse soliticação");
      }
      return ;
    }
    showToast('Saldo Adicionado com sucesso!', SUCCESS);
    props.onSuccess();
  }

  return (
    <Modal toggle={props.toggle} isOpen={props.isOpen}>
      <ConvertModalContent>
        <h4>Use o valor de Saque disponível para adicionar créditos.</h4>
        <label>
          Valor que deseja converter:
          <UnderlinedInput
            name="amount_credit"
            placeholder="Z$ 5,00"
            type="number"
            onChange={({target}) => setValue(target.value)}
          />
        </label>
        <Alert className="feedback-msg" show={error}>{error}</Alert>
        <AlertSuccess className="feedback-msg" show={success}>{success}</AlertSuccess>
        <SubmitButton disabled={loading} className="submit-btn" onClick={send}>
          {loading ? 'Carregando...' : 'Confirmar'}
        </SubmitButton>
      </ConvertModalContent>
    </Modal>
  );
};

export default ConvertModal;
