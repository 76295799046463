import axios from "./config";

export const getFAQ = async (token, page) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/faq/list/?page=${page}`,
      config
    );
    return result.data;
  } catch (error) {
    console.log(error.response);
    return { error: error.response };
  }
}

export const getTransactions = async (user, token, page) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/transaction/?user=${user}&page=${page}`,
      config
    );
    return result.data;
  } catch (error) {
    console.log(error.response);
    return { error: error.response };
  }
}

export const getBalance = async (user, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  try {
    const result = await axios.get(
      `/cashbox/user/?user=${user}`,
      config
    );
    return result.data;
  } catch (error) {
    console.log(error.response);
    return { error: error.response };
  }
}

export const sendContactMessage = async (payload, token) => {

  const config = {
    headers: { Authorization: `Token ${token}` }
  };

  try {
    const result = await axios.post(`/form/`, payload, config);
    return { error: false, data: result.data };
  } catch (error) {
    console.log(error.response);
    return { error:true, message:error.response }
  }
}

export const getTeams = async (token, page=1, search=null) => {
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  let params = {};
  if(search) {
    params = {...params, search};
  }
  try {
    const result = await axios.get(
      `/team/?page=${page}`, {
        headers: { Authorization: `Token ${token}` },
        params
      }
    );
    return result.data;
  } catch (error) {
    console.log(error.response);
    return { error: error.response };
  }
}

export const buyCredit = async (payload, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` },
  }
  try {
    const result = await axios.post('/checkout/', payload, config);
    return result.data;
  } catch (error) {
    console.log(error);
    console.log(error.response);
    return { error: true, message: error.response };
  }
}

export const requestRelease = async (payload, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` },
  }
  try {
    const result = await axios.post('/release/', payload, config);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return { error: true, message: error.response };
  }
}

export const convertWithdraw = async (payload, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` },
  }
  try {
    const result = await axios.post('/cashbox/user/conversion/', payload, config);
    return result.data;
  } catch (error) {
    console.log(error.response);
    return { error: true, message: error.response };
  }
}

export const importBets = async (user, from, to, token) => {
  const config = {
    headers: { Authorization: `Token ${token}` },
  }
  try {
    const result = await axios.post(`/guess/import/`, {
      user, import: from, receive: to
    }, config);
    return result.data
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

export const boletoPay = async (token, payload) => {
  const config = {
    headers: { Authorization: `Token ${token}` },
  }
  try {
    const result = await axios.post(`/boleto/`, payload, config);
    return result.data
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response };
  }
}

export const getAddressCEP = async(token, postcode) => {
  const config = {
    headers: { Authorization: `Token ${token}` },
    params: {
      postcode: postcode
    }
  }
  try {
    const result = await axios.get(`/address/`, config);
    return result.data
  } catch (error) {
    return { error: true, message: error.response };
  }
}