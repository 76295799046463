export const getWeekDay = (dateString) => {
  const day = new Date(dateString).getDay();
  const days = [
    'Domingo',
    'Segunda-Feira',
    'Terça-Feira',
    'Quarta-Feira',
    'Quinta-Feira',
    'Sexta-Feira',
    'Sábado'
  ];
  return days[day];
}

export const getHourAndMinutes = (dateString) => {
  const date = new Date(dateString);
  const hour = date.getHours();
  let minutes = date.getMinutes();

  if(minutes < 10) {
    minutes = `0${minutes}`;
  }

  return `${hour}:${minutes}`;
}
