import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OutlineButton } from '../../components/Button';
import { Alert } from '../../components/Form';
import Spinner from '../../components/LoadingSpinner';
import { getFAQ } from '../../providers/userServicesProvider';

import './styles.css';

const FAQ = props => {

  const user = useSelector(state => state.auth.user);
  //console.log(user);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(false);

  useEffect(() => {
    console.log("page", page);
    if(limit) return;
    if(loading) return;
    if(!user.token) return;
    const getData = async () => {
      setLoading(true);
      const result = await getFAQ(user.token, page);
      if(result.error) {
        setError("Houve um problema ao carregar as perguntas");
        setLoading(false);
        return;
      }
      console.log(result.results);
      setList(state => [...state, ...result.results]);
      if(!result.next) {
        setLimit(true);
      }
      setLoading(false);
    }
    getData();
  }, [user.token, page, limit])

  const nextPage = () => {
    if(limit) return;
    setPage(page + 1);
  }

  return (
      <main className="faq-screen">

        <h1>Suporte</h1>
        {loading ? <Spinner size="large"/> :
        <ul className="faq-content">
          {list.map(item => (
            <li key={`faq-item-${item.id}`} className="faq-item">
              <h2>{item.question}</h2>
              <p>{item.answer}</p>
            </li>
          ))}
        </ul> }

        <Alert className="error-msg" show={error}>{error}</Alert>

        {!loading && !limit ? (
          <OutlineButton className="pagination-btn" onClick={nextPage}>Ver mais</OutlineButton>
        ) : null}

      </main>
      
  )
}

export default FAQ;
